import { Dimensions } from 'react-native';
import { Circle } from 'react-native-svg';

import { useTheme } from '../../styles';
import { LineChart as RNLineChart, XAxis, YAxis } from '../../svgCharts';
import { Text } from '../Text/Text';
import { View } from '../View';

export const chartInset = { top: 16, bottom: 16, left: 16 + 8, right: 16 };

export const LineChart = ({
  data,
  yAxis,
  xAxis,
  xAxisLabel,
  lineColor = '#5A79E8',
  title,
  width = Dimensions.get('window').width,
  height = 300,
  'aria-label': ariaLabel,
}: {
  data: {
    date: string;
    value: number;
  }[];
  yAxis?: JSX.Element;
  xAxis?: JSX.Element;
  xAxisLabel?: string;
  lineColor?: string;
  title?: string;
  width?: number;
  height?: number;
  'aria-label': string;
}) => {
  const { theme } = useTheme();

  if (!data || data.length === 0) {
    return (
      <View style={{ width, height, justifyContent: 'center', alignItems: 'center' }}>
        <Text text="No data available" />
      </View>
    );
  }

  const values = data.map((item) => item.value);

  const axesSvg = {
    fontSize: 13,
    fill: '#242226',
    fontFamily: 'OpenSansRegular',
    lineHeight: 17.7,
  };

  const Decorator = ({
    x,
    y,
    data,
  }: {
    x: (index: number) => number;
    y: (value: number) => number;
    data: number[];
  }) => {
    return (
      <>
        {data.map((value, index) => (
          <Circle
            key={index}
            cx={x(index)}
            cy={y(value)}
            r={4}
            stroke={lineColor}
            fill={lineColor}
          />
        ))}
      </>
    );
  };

  const xAxisHeight = 30;

  const defaultYAxis = (
    <YAxis
      style={{
        paddingRight: 4,
      }}
      data={values}
      contentInset={{ top: chartInset.top, bottom: chartInset.bottom + xAxisHeight }}
      svg={axesSvg}
      numberOfTicks={4}
    />
  );

  const defaultXAxis = (
    <XAxis
      style={{
        height: xAxisHeight,
        marginLeft: 10,
        paddingTop: 7,
      }}
      data={data}
      xAccessor={({ item }) => new Date(item.date).getTime()}
      formatLabel={(value) => {
        const date = new Date(value);
        return `${date.getMonth() + 1}/${date.getDate()}`;
      }}
      contentInset={{ left: chartInset.left, right: chartInset.right }}
      svg={axesSvg}
    />
  );

  return (
    <View style={{ gap: 16 }} role="img" aria-label={ariaLabel}>
      {title && <Text weight="semibold" text={title} />}
      <View style={{ height, flexDirection: 'row' }}>
        {yAxis || defaultYAxis}
        <View style={{ flex: 1 }}>
          <RNLineChart
            style={{
              flex: 1,
              borderColor: theme.color.gray600,
              borderLeftWidth: 1,
              borderBottomWidth: 1,
            }}
            data={values}
            contentInset={chartInset}
            svg={{ stroke: lineColor, strokeWidth: 3 }}
          >
            <Decorator
              x={(index) =>
                (index * (width - chartInset.left - chartInset.right)) / (data.length - 1)
              }
              y={(value) => value}
              data={values}
            />
          </RNLineChart>
          {xAxis || defaultXAxis}
          {xAxisLabel ? <Text weight="semibold" text={xAxisLabel} textAlign="center" /> : null}
        </View>
      </View>
    </View>
  );
};
