import { createContext, PropsWithChildren, useCallback, useContext, useRef, useState } from 'react';

import { useTheme } from '@oui/app-core/src/styles';

import { StreakModal } from './StreakModal/StreakModal';

type EatingLogTheme = {
  primaryColor: string;
  name: string;
  onChangeStreak: (newValue: number) => void;
};

const EatingLogContext = createContext<EatingLogTheme>({
  primaryColor: 'black',
  name: 'Eating log',
  onChangeStreak: () => {},
});

export function EatingLogProvider({
  children,
  ...props
}: PropsWithChildren<Partial<Omit<EatingLogTheme, 'streak'>>>) {
  const { theme } = useTheme();
  const streakRef = useRef<number | null>(null);
  const [showStreakModal, setShowStreakModal] = useState(false);

  const onChangeStreak = useCallback((val: number) => {
    if (streakRef.current === null) {
      streakRef.current = val;
      return;
    }
    if (streakRef.current < val) {
      streakRef.current = val;
      setShowStreakModal(true);
    }
  }, []);

  return (
    <EatingLogContext.Provider
      value={{
        name: 'Eating log',
        primaryColor: theme.color.accentThree100,
        ...props,
        onChangeStreak,
      }}
    >
      {children}
      {showStreakModal && typeof streakRef.current === 'number' ? (
        <StreakModal
          streak={streakRef.current}
          onRequestClose={() => {
            setShowStreakModal(false);
            props.onChangeStreak?.(streakRef.current || 0);
          }}
        />
      ) : null}
    </EatingLogContext.Provider>
  );
}

export function useEatingLogContext() {
  return useContext(EatingLogContext);
}
