import { useNavigation } from '@react-navigation/native';
import { useLayoutEffect } from 'react';

import { Button } from '@oui/app-core/src/components/Button';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';

export function useScreenNavigation({
  onSave,
  onClose,
}: {
  onSave: () => void | Promise<void>;
  onClose: () => void | Promise<void>;
}) {
  const navigation = useNavigation();
  const { theme } = useTheme();
  const { $t } = useI18n();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Edit MyPlan',
      headerLeft: () => (
        <HeaderButtons left>
          <HeaderItem
            testID="EditBingeEatingMyPlan_closeButton"
            iconName="close"
            color={theme.color.primary100}
            title=""
            onPress={onClose}
            aria-label={$t({
              id: 'EditBingeEatingMyPlan_cancelButton',
              defaultMessage: 'Cancel',
            })}
          />
        </HeaderButtons>
      ),
      headerRight: () => (
        <HeaderButtons>
          <Button
            testID="EditBingeEatingMyPlan_saveButton"
            text={$t({ id: 'EditBingeEatingMyPlan_saveButton', defaultMessage: 'Save' })}
            color={theme.color.primary100}
            onPress={onSave}
            style={{
              backgroundColor: 'transparent',
              borderColor: theme.color.primary100,
            }}
          />
        </HeaderButtons>
      ),
      headerTitleAlign: 'center',
      headerStyle: {
        backgroundColor: theme.color.gray800,
        shadowColor: 'transparent',
        elevation: 0,
        borderBottomWidth: 0,
      },
    });
  }, [$t, navigation, theme, onSave, onClose]);
}
