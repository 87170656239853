import subDays from 'date-fns/subDays';

import { formatGQLDate, parseGQLDateTime, parseGQLTime } from './gqlDate';
import { FragmentOf, graphql, readFragment } from './graphql/tada';
import { EatingLogMealType } from './types';
import { EatingSchedule } from './types/avro';
import { GQLDate } from './types/scalars';

const ICON_BY_MEALTYPE = {
  BREAKFAST: 'coffee',
  LUNCH: 'sandwich',
  DINNER: 'meal',
  SNACK: 'apple',
} as const satisfies Record<EatingLogMealType, string>;

const LABEL_BY_MEALTYPE = {
  BREAKFAST: 'Breakfast',
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  SNACK: 'Snack',
} satisfies Record<EatingLogMealType, string>;

export function getEatingLogMealTypeContent(mealType: EatingLogMealType) {
  return {
    icon: ICON_BY_MEALTYPE[mealType],
    label: LABEL_BY_MEALTYPE[mealType],
  };
}

export const EatingScheduleCompositionFragment = graphql(`
  fragment EatingScheduleComposition on Composition {
    ID
    sections {
      ID
      json
    }
  }
`);

export function getEatingScheduleFromComposition(
  fragment: FragmentOf<typeof EatingScheduleCompositionFragment> | null | undefined,
) {
  const data = readFragment(EatingScheduleCompositionFragment, fragment);
  return data?.sections[0]?.json ? EatingSchedule.parse(data.sections[0].json) : null;
}

export function getPrecedingMealTypeForSnack(
  schedule: EatingSchedule,
  timeOfDay: string,
): EatingLogMealType {
  const time = parseGQLTime(timeOfDay || '00:00');
  const lunch = parseGQLTime(schedule.meals.lunch.time);
  const dinner = parseGQLTime(schedule.meals.dinner.time);
  if (time < lunch) return 'BREAKFAST';
  if (time < dinner) return 'LUNCH';
  return 'DINNER';
}

export function getSnackLabelForTimeOfDay(schedule: EatingSchedule, timeOfDay: string): string {
  const mealType = getPrecedingMealTypeForSnack(schedule, timeOfDay);
  const label = { BREAKFAST: 'Morning', LUNCH: 'Afternoon', DINNER: 'Evening', SNACK: 'A' }[
    mealType
  ];
  return `${label} snack`;
}

export function getEatingLogCurrentStreak(dates: GQLDate[]) {
  if (dates.length === 0) return 0;
  const datesSet = new Set(dates);
  const today = formatGQLDate();

  let currentDate = today;
  let streak = datesSet.has(today) ? 1 : 0;

  while (true) {
    currentDate = formatGQLDate(subDays(parseGQLDateTime(currentDate), 1));
    if (!datesSet.has(currentDate)) break;
    streak++;
  }

  return streak;
}
