import hexToRgba from 'hex-to-rgba';

import { Avatar } from '@oui/app-core/src/components/Avatar/Avatar';
import { Label, Text } from '@oui/app-core/src/components/Text';
import { UnorderedList } from '@oui/app-core/src/components/UnorderedList';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { getPatientSocialSupporterRelation } from '@oui/lib/src/getPatientSocialSupporterRelation';
import { SupporterTypeEnum } from '@oui/lib/src/types/avro';

import { useBingeEatingMyPlanContext } from '../';

export const SocialSupporter = ({
  fullName,
  role,
  items,
  testID,
}: {
  fullName: string;
  role: SupporterTypeEnum | 'DETRACTOR';
  items: string[];
  testID?: string;
}) => {
  const { primaryColor } = useBingeEatingMyPlanContext();
  const { theme } = useTheme();

  const { $t } = useI18n();

  return (
    <View
      testID={testID}
      style={{
        gap: 12,
      }}
    >
      <View
        style={{
          backgroundColor: hexToRgba(
            role !== 'DETRACTOR' ? theme.color.accent300 : theme.color.primary300,
            0.3,
          ),
          marginHorizontal: -20,
          paddingHorizontal: 20,
          paddingTop: 7.5,
          paddingBottom: 11,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            gap: 10,
          }}
        >
          <Avatar name={fullName} />
          <View style={{ gap: 5 }}>
            <Label testID="SocialSupporter_name" text={fullName} />
            <Text
              testID="SocialSupporter_relation"
              weight="semibold"
              color={theme.color.gray300}
              size={15}
              text={getPatientSocialSupporterRelation({
                socialSupportRelation: role,
                $t,
              })}
            />
          </View>
        </View>
      </View>

      <UnorderedList
        color={primaryColor}
        style={{
          marginHorizontal: 15,
        }}
        items={items}
        weight="normal"
      />
    </View>
  );
};
