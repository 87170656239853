import { useApolloClient, useQuery } from '@apollo/client';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import getHours from 'date-fns/getHours';
import { useState } from 'react';
import { Image, RefreshControl } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { SegmentedProgressBar } from '@oui/app-core/src/components/SegmentedProgressBar';
import { SessionCard } from '@oui/app-core/src/components/SessionCard';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { LearnSessionsFragment } from '@oui/app-core/src/screens/Learn';
import { useTheme } from '@oui/app-core/src/styles';
import { EatingLogOverview } from '@oui/eating-log';
import { graphql } from '@oui/lib/src/graphql/tada';

import {
  WeeklyPractice,
  WeeklyPracticeFragment,
} from '@src/components/WeeklyPractice/WeeklyPractice';
import { TabScreenProps } from '@src/types/navigation';

export const HomeQuery = graphql(
  `
    query Home {
      user {
        ID
        role {
          ID
          ...LearnSessions
        }
      }
      actionTodos {
        ...WeeklyPractice
      }
    }
  `,
  [LearnSessionsFragment, WeeklyPracticeFragment],
);

export function Home(_props: {}) {
  const { $t } = useI18n();
  const navigation = useNavigation<TabScreenProps<'Home'>['navigation']>();
  const { data } = useQuery(HomeQuery);
  const isFocused = useIsFocused();
  const { theme } = useTheme();
  const currentHour = getHours(new Date());
  const { top } = useSafeAreaInsets();
  const client = useApolloClient();
  const [refetching, setRefetching] = useState(false);

  const item =
    data?.user?.role?.sessions.find((s) => s.status === 'IN_PROGRESS') ||
    data?.user?.role?.sessions.find((s) => s.status === 'UNLOCKED');
  const sessionNum =
    (data?.user?.role?.sessions.findIndex((s) => s.session.sessionID === item?.session.sessionID) ??
      0) + 1;

  return (
    <View style={{ flex: 1 }}>
      {isFocused ? <SystemBars style="dark" /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refetching}
            onRefresh={() => {
              setRefetching(true);
              client.reFetchObservableQueries().finally(() => {
                setRefetching(false);
              });
            }}
            title={$t({ id: 'Home_refreshIndicator', defaultMessage: 'Update' })}
            progressViewOffset={150}
          />
        }
        testID="Home_scrollView"
        style={{ flex: 1, backgroundColor: theme.color.gray800 }}
        contentContainerStyle={{
          paddingTop: 20 + top,
          paddingBottom: 150,
          flexGrow: 1,
          gap: 25,
        }}
        bottomOverflowColor={theme.color.gray800}
      >
        <View row style={{ gap: 10, paddingHorizontal: 20, alignItems: 'stretch' }}>
          <Image
            source={require('../assets/AvatarWaving.png')}
            style={{ aspectRatio: 48 / 37, height: '100%' }}
          />
          <Heading
            level={1}
            text={
              currentHour < 12
                ? 'Good morning!'
                : currentHour < 18
                  ? 'Good afternoon!'
                  : 'Good evening!'
            }
          />
        </View>
        {item ? (
          <View
            style={{
              backgroundColor: 'white',
              borderRadius: 20,
              paddingHorizontal: 20,
              paddingVertical: 15,
              gap: 10,
            }}
          >
            <Heading level={3} text="Learn" />
            <SessionCard
              testID={`SessionCard_${item.session.contentType}`}
              state={item.status === 'COMPLETED' ? 'complete' : 'unlocked'}
              session={{
                num: sessionNum,
                details: [], // TODO
                title: item.session.name,
                subtitle: item.session.description,
                illustrationUrl: item.session.illustration?.gcsPath,
              }}
              onPressTop={() => {
                navigation.navigate('Conversation', {
                  num: sessionNum.toString(),
                  title: item.session.name,
                  ID: item.session.contentType || 'TEST::convo',
                  completed: item.status === 'COMPLETED' ? ('true' as const) : ('false' as const),
                });
              }}
            />
            <View
              row
              spacing={10}
              style={{ marginLeft: 6 }}
              accessible
              aria-label={$t(
                {
                  id: 'Home_sessionsDoneAccessibilityLabel',
                  defaultMessage: 'Session progress: {completedCount} of {totalCount} complete',
                },
                {
                  completedCount: sessionNum - 1,
                  totalCount: data?.user?.role?.sessions.length ?? 12,
                },
              )}
            >
              <Text
                text={$t({ id: 'Home_sessionsDone', defaultMessage: 'Sessions done' })}
                weight="semibold"
                size={15}
                color={theme.color.gray300}
              />
              <Text
                text={$t(
                  {
                    id: 'Home_sessionProgress',
                    defaultMessage: `{completedCount}/{totalCount}`,
                  },
                  {
                    completedCount: sessionNum - 1,
                    totalCount: data?.user?.role?.sessions.length ?? 12,
                  },
                )}
                testID="Home_sessionProgress"
                size={15}
                color={theme.color.gray300}
              />
              <SegmentedProgressBar
                length={data?.user?.role?.sessions.length ?? 12}
                height={6}
                progress={sessionNum - 1}
              />
            </View>
          </View>
        ) : null}
        <EatingLogOverview />
        {data?.actionTodos ? <WeeklyPractice practice={data.actionTodos} /> : null}
      </ScrollView>
    </View>
  );
}
