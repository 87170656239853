import type { RouteProp } from '@react-navigation/core';
import type { StackNavigationProp } from '@react-navigation/stack';

import { CoreRootStackParamList, DeeplinkConfigShape } from '@oui/app-core/src/types/navigation';

export type BingeEatingMyPlanStackParamList = {
  BingeEatingMyPlan: undefined;
  EditDistractingActivities: undefined;
  EditMoodPlan: undefined;
  EditPreventingRelapse: undefined;
  EditSocialSupport: undefined;
};

export const BINGE_EATING_MY_PLAN_DEEPLINK_CONFIG = {
  BingeEatingMyPlan: 'myplan',
  EditDistractingActivities: 'myplan/distracting-activities/edit',
  EditMoodPlan: 'myplan/mood-plan/edit',
  EditPreventingRelapse: 'myplan/preventing-relapse/edit',
  EditSocialSupport: 'myplan/social-support/edit',
} as const satisfies DeeplinkConfigShape<keyof BingeEatingMyPlanStackParamList>;

type FullStackParamList = BingeEatingMyPlanStackParamList & CoreRootStackParamList;

export type StackScreenProps<
  T extends keyof BingeEatingMyPlanStackParamList,
  Other extends object = {},
> = {
  navigation: StackNavigationProp<FullStackParamList, T>;
  route: RouteProp<FullStackParamList, T>;
} & Other;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends FullStackParamList {}
  }
}
