import { ReactNode, useState } from 'react';
import { Pressable } from 'react-native';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { formatDateToWeekRange } from '@oui/lib/src/formatDateToWeekRange';
import {
  getEatingCommitmentHealthyFoodLabel,
  getEatingCommitmentUnhealthyFoodLabel,
} from '@oui/lib/src/getEatingCommitmentEnumLabel';
import { FragmentOf, graphql, readFragment } from '@oui/lib/src/graphql/tada';
import { GQLDate } from '@oui/lib/src/types/scalars';

import {
  CommitmentCheckIn,
  CommitmentCheckInFragment,
} from '../CommitmentCheckIn/CommitmentCheckIn';

export const EatingCommitmentEntryRowFragment = graphql(
  `
    fragment EatingCommitmentEntryRow on EatingCommitmentEntryPractice {
      practiceValues {
        date
      }
      eatingCommitmentEntry {
        decreaseCommitment
        decreaseSuccess
        increaseCommitment
        increaseSuccess
      }
      ...CommitmentCheckIn
    }
  `,
  [CommitmentCheckInFragment],
);

const Row = (props: {
  date: GQLDate;
  children: ReactNode;
  'aria-label': string;
  checkin: FragmentOf<typeof CommitmentCheckInFragment>;
}) => {
  const [showCheckin, setShowCheckin] = useState(false);
  return (
    <>
      {showCheckin && (
        <CommitmentCheckIn
          entry={props.checkin}
          onClose={() => {
            setShowCheckin(false);
          }}
        />
      )}
      <Pressable
        onPress={() => {
          setShowCheckin(true);
        }}
        aria-label={props['aria-label']}
        style={({ pressed }) => ({ opacity: pressed ? 0.5 : undefined })}
      >
        <View
          row
          style={{
            alignItems: 'flex-start',
          }}
        >
          <Label
            style={{
              flex: 1,
            }}
            text={formatDateToWeekRange(props.date)}
          />
          {props.children}
        </View>
      </Pressable>
    </>
  );
};

export const EatingCommitmentEntryRow = (props: {
  entry: FragmentOf<typeof EatingCommitmentEntryRowFragment>;
}) => {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const entry = readFragment(EatingCommitmentEntryRowFragment, props.entry);

  const noCheckin =
    entry?.eatingCommitmentEntry.decreaseSuccess === null &&
    entry?.eatingCommitmentEntry.increaseSuccess === null;

  return noCheckin ? (
    <Row date={entry?.practiceValues.date} aria-label="Add check-in" checkin={entry}>
      <View
        row
        style={{
          gap: 10,
          flex: 1,
        }}
      >
        <Icon name="plus" color={theme.color.primary100} size={20} />
        <Label text="Add check-in" color={theme.color.primary100} />
      </View>
    </Row>
  ) : (
    <Row date={entry?.practiceValues.date} aria-label="Edit check-in" checkin={entry}>
      <View
        row
        style={{
          flex: 1,
          gap: 10,
          alignItems: 'flex-start',
        }}
      >
        <View
          style={{
            flex: 1,
            gap: 10,
          }}
        >
          {entry?.eatingCommitmentEntry.increaseSuccess ? (
            <Text
              text={$t(
                {
                  id: 'EatingCommitmentEntryRow_increased',
                  defaultMessage: 'Increased {healthyFood}',
                },
                {
                  healthyFood: getEatingCommitmentHealthyFoodLabel({
                    $t,
                    eatingCommitmentHealthyFood: entry.eatingCommitmentEntry.increaseCommitment,
                  }),
                },
              )}
            />
          ) : entry?.eatingCommitmentEntry.increaseSuccess === null ? (
            <Text
              color={theme.color.gray400}
              text={$t(
                {
                  id: 'EatingCommitmentEntryRow_noAnswer',
                  defaultMessage: 'No answer for {food}',
                },
                {
                  food: getEatingCommitmentHealthyFoodLabel({
                    $t,
                    eatingCommitmentHealthyFood: entry.eatingCommitmentEntry.increaseCommitment,
                  }),
                },
              )}
            />
          ) : (
            <Text
              text={$t(
                {
                  id: 'EatingCommitmentEntryRow_didNotIncrease',
                  defaultMessage: 'Did not increase {healthyFood}',
                },
                {
                  healthyFood: getEatingCommitmentHealthyFoodLabel({
                    $t,
                    eatingCommitmentHealthyFood: entry!.eatingCommitmentEntry.increaseCommitment,
                  }),
                },
              )}
            />
          )}

          {entry?.eatingCommitmentEntry.decreaseSuccess ? (
            <Text
              text={$t(
                {
                  id: 'EatingCommitmentEntryRow_reduced',
                  defaultMessage: 'Reduced {unhealthyFood}',
                },
                {
                  unhealthyFood: getEatingCommitmentUnhealthyFoodLabel({
                    $t,
                    eatingCommitmentUnhealthyFood: entry.eatingCommitmentEntry.decreaseCommitment,
                  }),
                },
              )}
            />
          ) : entry?.eatingCommitmentEntry.decreaseSuccess === null ? (
            <Text
              color={theme.color.gray400}
              text={$t(
                {
                  id: 'EatingCommitmentEntryRow_noAnswer',
                  defaultMessage: 'No answer for {food}',
                },
                {
                  food: getEatingCommitmentUnhealthyFoodLabel({
                    $t,
                    eatingCommitmentUnhealthyFood: entry.eatingCommitmentEntry.decreaseCommitment,
                  }),
                },
              )}
            />
          ) : (
            <Text
              text={$t(
                {
                  id: 'EatingCommitmentEntryRow_didNotReduce',
                  defaultMessage: 'Did not reduce {unhealthyFood}',
                },
                {
                  unhealthyFood: getEatingCommitmentUnhealthyFoodLabel({
                    $t,
                    eatingCommitmentUnhealthyFood: entry!.eatingCommitmentEntry.decreaseCommitment,
                  }),
                },
              )}
            />
          )}
        </View>

        <Icon name="edit" color={theme.color.gray500} size={20} />
      </View>
    </Row>
  );
};
