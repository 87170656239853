import { useQuery } from '@apollo/client';
import addDays from 'date-fns/addDays';
import getWeek from 'date-fns/getWeek';
import isSameMonth from 'date-fns/isSameMonth';
import startOfWeek from 'date-fns/startOfWeek';
import { ComponentProps, FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Image, TextStyle } from 'react-native';
import {
  CalendarProvider,
  Calendar as RNCalendar,
  WeekCalendar as RNWeekCalendar,
} from 'react-native-calendars';
import { type CalendarHeaderProps } from 'react-native-calendars/src/calendar/header/index';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useWindowDimensions } from '@oui/app-core/src/hooks/useWindowDimensions';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useCalendars } from '@oui/app-core/src/lib/localization';
import { useTheme } from '@oui/app-core/src/styles';
import { getEatingLogCurrentStreak } from '@oui/lib/src/eatingLog';
import { formatGQLDate, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { GQLDate } from '@oui/lib/src/types/scalars';

import { useEatingLogContext } from '../EatingLogContext';

const Calendar: FC<ComponentProps<typeof RNCalendar>> = RNCalendar;
const WeekCalendar: FC<ComponentProps<typeof RNWeekCalendar>> = RNWeekCalendar;

export type EatingLogCalendarQueryName = 'EatingLogCalendar';
export const EatingLogCalendarQuery = graphql(`
  query EatingLogCalendar {
    user {
      ID
      role {
        ID
        eatingLogEntries {
          practiceID
          practiceValues {
            date
          }
        }
      }
    }
  }
`);

function StreakIndicator(props: { streak: number }) {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const { onChangeStreak } = useEatingLogContext();

  useEffect(() => {
    onChangeStreak(props.streak);
  }, [props.streak, onChangeStreak]);

  if (props.streak === 0) return null;
  return (
    <View row style={{ gap: 5 }}>
      <Image
        source={require('../../assets/Fire.png')}
        style={{ width: 10, height: 15, resizeMode: 'contain' }}
      />
      <Text
        text={$t(
          {
            id: 'EatingLogCalendar_streakLabel',
            defaultMessage: '{count, plural, one{1 day streak} other{# day streak}}',
          },
          { count: props.streak },
        )}
        weight="semibold"
        size={15}
        color={theme.color.gray300}
      />
    </View>
  );
}

function CustomHeader(
  props: Omit<CalendarHeaderProps, 'month'> & { month: [Date]; streak: number; loading: boolean },
) {
  const { formatDate } = useI18n();
  const { theme } = useTheme();
  const month = props.month[0];

  const start = startOfWeek(new Date());
  const dates = [0, 1, 2, 3, 4, 5, 6].map((i) => addDays(start, i));

  return (
    <View style={{ marginBottom: 15, gap: 25 }}>
      <View
        row
        style={{
          justifyContent: 'space-between',
          paddingHorizontal: 20,
        }}
      >
        <View row style={{ gap: 15 }}>
          {props.disableArrowLeft ? null : (
            <Icon
              name="caret-left"
              color={theme.color.gray400}
              aria-label="Previous month"
              onPress={() => props.addMonth?.(-1)}
            />
          )}
          <Label text={formatDate(month, { month: 'long', year: 'numeric' })} textAlign="center" />
          {props.disableArrowRight ? null : (
            <Icon
              name="caret-right"
              color={theme.color.gray400}
              aria-label="Next month"
              onPress={() => props.addMonth?.(1)}
              disabled={isSameMonth(new Date(), month)}
            />
          )}
        </View>
        {props.loading ? null : <StreakIndicator streak={props.streak} />}
      </View>
      <View row style={{ justifyContent: 'space-around' }} testID="WeeklyProgress">
        {dates.map((date) => {
          const dateName = formatDate(date, { weekday: 'long' });
          return <Text key={dateName} text={dateName[0]} size={13} />;
        })}
      </View>
    </View>
  );
}

/**
 * Renders a calendar view for eating log entries
 */
export const EatingLogCalendar = ({
  date,
  onChangeDate,
  width,
  view,
}: {
  date: GQLDate;
  onChangeDate: (newDate: GQLDate) => void;
  width?: number;
  view: 'week' | 'month' | 'condensed';
}) => {
  const dateRef = useRef(date);
  dateRef.current = date;
  const dimensions = useWindowDimensions();
  const { data, loading } = useQuery(EatingLogCalendarQuery);
  const role = data?.user ? data?.user?.role : null;
  const { theme } = useTheme();
  const calendar = useCalendars()[0]!;
  const { formatDate } = useI18n();
  const { onChangeStreak } = useEatingLogContext();

  const datesWithEntries = useMemo(
    () => new Set(role?.eatingLogEntries.map((e) => e.practiceValues.date) ?? []),
    [role],
  );

  const { markedDates, streak } = useMemo(() => {
    const today = formatGQLDate();

    const result: ComponentProps<typeof Calendar>['markedDates'] = {};

    for (let key of datesWithEntries) {
      result[key] = {
        dotColor: theme.color.primary100,
        selectedColor: theme.color.primary100,
        marked: true,
      };
    }

    const selectedStyles: (typeof result)[string] = {
      selected: true,
      selectedColor: theme.color.primary100,
      marked: false,
    };

    if (!result[date]) {
      result[date] = selectedStyles;
    } else {
      Object.assign(result[date], selectedStyles);
    }

    const todayStyles: (typeof result)[string] = {
      today: true,
      marked: false,
    };

    if (!result[today]) {
      result[today] = todayStyles;
    } else {
      Object.assign(result[today], todayStyles);
    }

    const streak = getEatingLogCurrentStreak(Array.from(datesWithEntries));

    return { markedDates: result, streak };
  }, [theme, date, datesWithEntries]);

  const Component = view === 'week' ? WeekCalendar : Calendar;
  const calendarTheme: ComponentProps<typeof CalendarProvider>['theme'] = {
    calendarBackground: theme.color.accentThree300,
    todayDotColor: theme.color.primary100,
    textInactiveColor: theme.color.gray400,
    textDayStyle: {
      color: theme.color.gray100,
      fontFamily: 'OpenSansSemiBold',
      fontSize: 15,
    },
    dotStyle: { width: 6, height: 6, borderRadius: 6 },
    textDayHeaderFontFamily: 'OpenSansRegular',
    stylesheet: {
      expandable: {
        main: {
          // https://github.com/wix/react-native-calendars/blob/dbc3be7432e6d3acdcfa091b3c3eb4cd5bc3d179/src/expandableCalendar/style.ts#L111C11-L118C6
          week: {
            marginTop: 7,
            marginBottom: 7,
            paddingHorizontal: 5,
            flexDirection: 'row',
            justifyContent: 'space-around',
          },
        },
      },
    },
    // https://github.com/wix/react-native-calendars/blob/dbc3be7432e6d3acdcfa091b3c3eb4cd5bc3d179/src/calendar/day/basic/style.ts#L71
    // @ts-expect-error types don't match actual usage
    'stylesheet.day.basic': {
      todayText: {
        marginTop: 4, // offset borderWidth
      },
      today: {
        borderRadius: 16,
        borderWidth: 2,
        borderColor: theme.color.primary100,
        padding: 0,
      },
      disabledText: {
        color: theme.color.gray400,
        fontFamily: 'OpenSansRegular',
      },
    } satisfies Record<string, TextStyle>,
  };

  // The events coming from the week/month calendar views are not consistent
  // and it's difficult to prevent double invocations. To guard against, we
  // keep track of the last known changed date in dateRef
  const handleChangeDate = useCallback(
    (newDate: string) => {
      if (newDate > formatGQLDate()) return;
      if (newDate === dateRef.current) return;
      dateRef.current = newDate as GQLDate;
      return onChangeDate(newDate as GQLDate);
    },
    [onChangeDate],
  );

  useEffect(() => {
    if (!loading && !datesWithEntries.size && view === 'condensed') {
      // if we are loaded and there are no entries, we initialize the streak so the modal displays
      // properly for the first added entry
      onChangeStreak(0);
    }
  }, [loading, datesWithEntries, view, onChangeStreak]);

  if (view === 'condensed') {
    if (!datesWithEntries.size) return null;

    const weekStart = startOfWeek(new Date(), {
      weekStartsOn: ((calendar.firstWeekday ?? 1) - 1) as 0 | 1 | 2 | 3 | 4 | 5 | 6,
    });
    let dates = [];
    for (let i = 0; i < 7; i = i + 1) {
      dates.push(addDays(weekStart, i));
    }

    return (
      <View style={{ gap: 15 }}>
        <View row style={{ justifyContent: 'space-between' }}>
          <Label text="This week’s progress" small color={theme.color.gray300} />
          {loading ? null : <StreakIndicator streak={streak} />}
        </View>
        <View row style={{ justifyContent: 'space-between' }} testID="WeeklyProgress">
          {dates.map((date) => {
            const hasEntry = datesWithEntries.has(formatGQLDate(date));
            const dateName = formatDate(date, { weekday: 'narrow' });
            return (
              <View
                style={{
                  borderWidth: 1,
                  borderColor: theme.color.gray600,
                  paddingVertical: 10,
                  paddingHorizontal: 8,
                  borderRadius: 10,
                  gap: 10,
                  alignItems: 'center',
                }}
                key={date.toISOString()}
              >
                <Text
                  text={date.getDate()}
                  size={15}
                  lineHeight={15}
                  style={{ minWidth: 18 }} // we want dates to render the same if 1 or 2 digits
                  textAlign="center"
                  weight="semibold"
                  color={hasEntry ? theme.color.success : theme.color.gray300}
                />
                {hasEntry ? (
                  <Icon name="check" size={13} color={theme.color.success} />
                ) : (
                  <Text key={dateName} text={dateName} size={13} lineHeight={14} />
                )}
              </View>
            );
          })}
        </View>
      </View>
    );
  }

  return (
    <CalendarProvider
      date={date}
      onDateChanged={handleChangeDate}
      theme={calendarTheme}
      // override default flex styles so that we don't mess up height measurement by parent element (EatingLogEntries)
      style={{ flex: 0 }}
    >
      {view === 'week' ? (
        <View style={{ paddingHorizontal: 5 }}>
          <CustomHeader
            loading={loading}
            month={[parseGQLDateTime(date)]}
            disableArrowLeft
            disableArrowRight
            streak={streak}
          />
        </View>
      ) : null}
      <Component
        // since we've patched react-native-calendars to resolve a week swiping bug, we no longer
        // are able to render weeks other than that of initialDate. by changing the key, we force
        // initialDate to be reprocessed
        key={view === 'week' ? getWeek(parseGQLDateTime(date)) : undefined}
        current={date}
        theme={calendarTheme}
        hideDayNames
        allowShadow={false}
        calendarWidth={width || dimensions.width}
        onDayPress={(date) => handleChangeDate(date.dateString as GQLDate)}
        // onDayLongPress is called sometimes by WeekCalendar instead of onDayPress when debugging
        onDayLongPress={(date) => handleChangeDate(date.dateString as GQLDate)}
        initialDate={date}
        customHeader={(props: ComponentProps<typeof CustomHeader>) => (
          <CustomHeader {...props} loading={props.loading || loading} streak={streak} />
        )}
        maxDate={formatGQLDate(new Date())}
        markedDates={markedDates}
        displayLoadingIndicator={loading}
        testID={`EatingLogCalendar_${view}`}
        disableAllTouchEventsForDisabledDays
      />
    </CalendarProvider>
  );
};
