import { useNavigation } from '@react-navigation/native';

import { View } from '@oui/app-core/src/components/View';
import { SocialSupport } from '@oui/lib/src/types/avro';

import { BingeEatingMyPlanSection } from '../BingeEatingMyPlanSection/BingeEatingMyPlanSection';
import { SocialSupporter } from '../SocialSupporter/SocialSupporter';

export function SocialSupportCard(props: {
  disabled: boolean;
  highlighted: boolean;
  contacts: SocialSupport | undefined;
}) {
  const navigation = useNavigation();
  const hasContacts = !!props.contacts?.detractors.length || !!props.contacts?.supporters.length;

  return (
    <BingeEatingMyPlanSection
      title="Social support"
      onPress={() => {
        navigation.navigate('EditSocialSupport');
      }}
      testID="SocialSupportCard"
      disabled={props.disabled}
      canEdit={hasContacts}
      highlighted={props.highlighted}
    >
      {hasContacts ? (
        <View
          style={{
            marginTop: 13,
            gap: 20,
          }}
        >
          {props.contacts?.supporters.map((supporter) => (
            <SocialSupporter
              testID="SocialSupportCard_supporters"
              key={supporter.contact.ID}
              role={supporter.type}
              fullName={`${supporter.contact.firstName} ${supporter.contact.lastName}`}
              items={supporter.activities.map((strategy) => strategy.text)}
            />
          ))}
          {props.contacts?.detractors.map((detractor) => (
            <SocialSupporter
              testID="SocialSupportCard_detractors"
              key={detractor.contact.ID}
              role="DETRACTOR"
              fullName={`${detractor.contact.firstName} ${detractor.contact.lastName}`}
              items={detractor.strategies.map((strategy) => strategy.text)}
            />
          ))}
        </View>
      ) : null}
    </BingeEatingMyPlanSection>
  );
}
