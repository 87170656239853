import isDate from 'date-fns/isDate';

import { parseGQLDateTime } from './gqlDate';
import { GQLDate, GQLDateTime } from './types/scalars';

type DateFormat =
  | 'monthDayYear'
  | 'shortMonthDayYearTime'
  | 'monthDay'
  | 'monthDayNumeric'
  | 'dayOfWeekMonthDay';

function isDateTS(
  originalDate: Date | GQLDate | GQLDateTime | null | undefined,
): originalDate is Date {
  return isDate(originalDate);
}

export const formatDate = ({
  originalDate,
  format = 'shortMonthDayYearTime',
  locale = 'en-US',
}: {
  originalDate: Date | GQLDate | GQLDateTime | null | undefined;
  format?: DateFormat;
  locale?: string;
}): string | null => {
  if (!originalDate) {
    return null;
  }

  let formatOptions: Intl.DateTimeFormatOptions;

  switch (format) {
    case 'monthDayYear':
      formatOptions = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };
      break;
    case 'monthDay':
      formatOptions = {
        month: 'short',
        day: 'numeric',
      };
      break;
    case 'monthDayNumeric':
      formatOptions = {
        month: 'numeric',
        day: 'numeric',
      };
      break;
    case 'dayOfWeekMonthDay':
      formatOptions = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
      };
      break;
    default:
      formatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
  }

  const date = isDateTS(originalDate) ? originalDate : parseGQLDateTime(originalDate);
  const formatter = new Intl.DateTimeFormat(locale, formatOptions);
  return formatter.format(date);
};
