import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useLayoutEffect, useState } from 'react';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { HeaderButtons } from '@oui/app-core/src/components/HeaderButtons';
import { OverflowMenu, OverflowMenuOption } from '@oui/app-core/src/components/OverflowMenu';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { formatDate } from '@oui/lib/src/formatDate';
import { graphql } from '@oui/lib/src/graphql/tada';

import {
  EatingLogCalendarQueryName,
  EatingLogEntriesListQueryName,
  EatingLogEntry as EatingLogEntryComponent,
  EatingLogEntryFragment,
  useEatingLogContext,
} from '../components';
import { StackScreenProps } from '../types/navigation';

export const EatingLogEntryDeleteMutation = graphql(`
  mutation EatingLogEntryDelete($practiceID: UUID!) {
    deleteEatingLogEntry(practiceID: $practiceID)
  }
`);

export const EatingLogEntryQuery = graphql(
  `
    query EatingLogEntry($practiceID: UUID!) {
      user {
        ID
        role {
          __typename
          ID
          eatingLogEntryByID(practiceID: $practiceID) {
            practiceID
            eatingLogEntry {
              timestamp
            }
            ...EatingLogEntry
          }
        }
      }
    }
  `,
  [EatingLogEntryFragment],
);

export function EatingLogEntry() {
  const { navigate, goBack, setOptions } =
    useNavigation<StackScreenProps<'EatingLogEntry'>['navigation']>();
  const {
    params: { practiceID },
  } = useRoute<StackScreenProps<'EatingLogEntry'>['route']>();
  const [deleteEatingLogEntry] = useMutation(EatingLogEntryDeleteMutation);
  const { data } = useQuery(EatingLogEntryQuery, {
    variables: { practiceID: practiceID },
  });
  const { primaryColor } = useEatingLogContext();
  const { $t } = useI18n();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const role = data?.user ? data?.user?.role : null;

  useLayoutEffect(() => {
    setOptions({
      headerRight: ({ tintColor }) => (
        <HeaderButtons>
          <OverflowMenu
            triggerTestID="EatingLogEntry_overflowMenuTrigger"
            headerItem
            triggerColor={tintColor}
          >
            <OverflowMenuOption
              text={$t({ id: 'EatingLogEntry_editButton', defaultMessage: 'Edit' })}
              icon="edit"
              testID="EatingLogEntry_editButton"
              onPress={() => {
                navigate('EditEatingLogEntry', {
                  practiceID,
                });
              }}
            />
            <OverflowMenuOption
              icon="bin"
              text={$t({ id: 'EatingLogEntry_deleteButton', defaultMessage: 'Delete' })}
              testID="EatingLogEntry_deleteButton"
              onPress={() => setShowDeleteConfirmationModal(true)}
            />
          </OverflowMenu>
        </HeaderButtons>
      ),
    });
  }, [setOptions, $t, navigate, practiceID, primaryColor]);

  return (
    <RoundedSection
      applyHeaderOptions
      color={primaryColor}
      secondaryColor="white"
      title={
        formatDate({
          originalDate: role?.eatingLogEntryByID?.eatingLogEntry.timestamp ?? undefined,
        }) ?? ''
      }
      preview={false}
      testID="EatingLogEntry_scrollView"
    >
      {role?.eatingLogEntryByID ? (
        <EatingLogEntryComponent practice={role?.eatingLogEntryByID} />
      ) : (
        <ActivityIndicator />
      )}
      <ConfirmationModal
        visible={!!showDeleteConfirmationModal}
        onCancel={() => setShowDeleteConfirmationModal(false)}
        onConfirm={async () => {
          await deleteEatingLogEntry({
            variables: { practiceID },
            refetchQueries: [
              'EatingLogEntriesList' satisfies EatingLogEntriesListQueryName,
              'EatingLogCalendar' satisfies EatingLogCalendarQueryName,
            ],
          });
          goBack();
        }}
        cancelText={$t({
          id: 'EatingLogEntry_confirmDeleteCancel',
          defaultMessage: "No, don't",
        })}
        confirmText={$t({
          id: 'EatingLogEntry_confirmDeleteConfirm',
          defaultMessage: 'Yes, delete',
        })}
        confirmTestID="EatingLogEntry_confirmDeleteButton"
        title={$t({ id: 'EatingLogEntry_confirmDeleteTitle', defaultMessage: 'Delete?' })}
        description={$t({
          id: 'EatingLogEntry_confirmDeleteDescription',
          defaultMessage: "Please confirm you'd like to delete this thought diary entry.",
        })}
      />
    </RoundedSection>
  );
}
