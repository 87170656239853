import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Button } from '@oui/app-core/src/components/Button';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { FormModal } from '@oui/app-core/src/components/FormModal';
import { PhoneInput } from '@oui/app-core/src/components/PhoneInput';
import { TextInput } from '@oui/app-core/src/components/TextInput';
import { View } from '@oui/app-core/src/components/View';
import { useContactsPickerResult } from '@oui/app-core/src/hooks/useContactsPickerResult';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import {
  MyStoryMyPlanContact,
  transformExpoContactToMyStoryMyPlanContact,
} from '@oui/lib/src/transformExpoContactToResponseContact';

const AddContactPicker = ({ onAdd }: { onAdd: (contacts: MyStoryMyPlanContact[]) => void }) => {
  const navigation = useNavigation();

  const returnRoute = useContactsPickerResult(({ contacts: expoContacts, permissionDenied }) => {
    if (!permissionDenied) {
      const newContacts = expoContacts.map((expoContact) => {
        const transformedContact = transformExpoContactToMyStoryMyPlanContact(expoContact);
        if (expoContact.image?.uri) {
          transformedContact.imageAssetKey = expoContact.image.uri;
        }
        return transformedContact;
      });
      onAdd(newContacts);
    }
  });

  useEffect(() => {
    navigation.navigate('ContactsPicker', { returnRoute });
  }, [navigation, returnRoute]);

  return null;
};
export const AddContact = ({
  onAdd,
  testID,
}: {
  onAdd: (contacts: MyStoryMyPlanContact[]) => void;
  testID?: string;
}) => {
  const [showContactsPicker, setShowContactsPicker] = useState(false);
  const { theme } = useTheme();

  const { $t } = useI18n();
  const [showManualContactModal, setShowManualContactModal] = useState(false);
  const { validate, clear, bind, data, humanErrors } = useForm<MyStoryMyPlanContact>({
    ID: '',
    firstName: '',
    lastName: '',
    phone: '',
    localDeviceID: '',
    imageBase64: '',
  });

  function addNewContacts(newContacts: MyStoryMyPlanContact[]) {
    onAdd(newContacts);
    setShowContactsPicker(false);
  }

  return (
    <View>
      {showContactsPicker ? <AddContactPicker onAdd={addNewContacts} /> : null}

      <Button
        text={$t({
          id: 'AddContact_addButton',
          defaultMessage: 'Add from contacts',
        })}
        testID="AddContact_addButton"
        alignSelf="center"
        icon="plus"
        onPress={() => {
          setShowContactsPicker(true);
        }}
      />

      <Button
        style={{
          marginTop: 7,
        }}
        text={$t({
          id: 'AddContact_addManuallyButton',
          defaultMessage: 'Or add manually',
        })}
        testID="AddContact_addManuallyButton"
        alignSelf="center"
        variant="text"
        size="small"
        color={theme.color.gray400}
        onPress={() => {
          setShowManualContactModal(true);
        }}
      />

      <FormModal
        testID={`AddContact_FormModal_${testID}`}
        visible={!!showManualContactModal}
        onCancel={() => {
          clear();
          setShowManualContactModal(false);
        }}
        onConfirm={() => {
          if (validate()) {
            addNewContacts([{ ...data, ID: uuid() }]);
            clear();
            setShowManualContactModal(false);
          }
        }}
        confirmText={$t({
          id: 'PatientMyPlanEditContacts_saveManuallyButton',
          defaultMessage: 'Add contact',
        })}
        confirmTestID="PatientMyPlanEditContacts_saveManuallyButton"
        title={$t({
          id: 'PatientMyPlanEditContacts_saveManuallyTitle',
          defaultMessage: 'Contact details',
        })}
      >
        <View
          spacing={20}
          style={{
            backgroundColor: theme.color.gray800,
            marginHorizontal: -20,
            padding: 20,
          }}
        >
          {Object.keys(humanErrors).length ? <ErrorPresenter formErrors={humanErrors} /> : null}
          <TextInput
            placeholder={$t({
              id: 'PatientMyPlanEditContacts_givenNamePlaceholder',
              defaultMessage: 'Sally',
            })}
            {...bind(['firstName'], {
              validator: { type: 'present' },
              label: $t({
                id: 'PatientMyPlanEditContacts_givenNameLabel',
                defaultMessage: 'First name*',
              }),
            })}
          />
          <TextInput
            placeholder={$t({
              id: 'PatientMyPlanEditContacts_familyNamePlaceholder',
              defaultMessage: 'Smith',
            })}
            {...bind(['lastName'], {
              validator: { type: 'present' },
              label: $t({
                id: 'PatientMyPlanEditContacts_familyNameLabel',
                defaultMessage: 'Last name*',
              }),
            })}
          />
          <PhoneInput
            {...bind(['phone'], {
              validator: { type: 'phone', allowBlank: true },
              label: $t({
                id: 'PatientMyPlanEditContacts_phoneLabel',
                defaultMessage: 'Phone number',
              }),
            })}
          />
        </View>
      </FormModal>
    </View>
  );
};
