import BottomSheet, { BottomSheetScrollView } from '@gorhom/bottom-sheet';
import addDays from 'date-fns/addDays';
import isSameDay from 'date-fns/isSameDay';
import { useState } from 'react';
import { ViewProps } from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { GQLDate } from '@oui/lib/src/types/scalars';

import { EatingLogCalendar } from '../EatingLogCalendar/EatingLogCalendar';
import { EatingLogEntriesList } from '../EatingLogEntriesList/EatingLogEntriesList';

const BOTTOM_SHEET_MARGIN = 10;

/**
 * Renders the calendar and entries list in a combined layout. Responsible for controlling selected date
 */
export const EatingLogEntries = ({
  date: initialDate,
  paddingBottom,
}: {
  date?: GQLDate;
  paddingBottom?: number;
}) => {
  const animatedIndex = useSharedValue(1);
  const { theme } = useTheme();
  const { formatDate } = useI18n();
  const [parentDimensions, setParentDimensions] = useState({ width: 0, height: 0 });
  const [calendarHeight, setCalendarHeight] = useState(0);
  const [date, setDate] = useState(initialDate || formatGQLDate());

  const monthCalendarStyle = useAnimatedStyle(() => {
    const opacity =
      // On mount, animatedIndex.value jumps to -1 temporarily
      animatedIndex.value === -1
        ? 0
        : interpolate(animatedIndex.value, [0, 1], [1, 0], {
            extrapolateLeft: Extrapolation.CLAMP,
            extrapolateRight: Extrapolation.CLAMP,
          });
    const pointerEvents = opacity === 0 ? 'none' : 'auto';
    return { opacity, pointerEvents };
  }, [animatedIndex]);

  const monthCalendarProps: ViewProps = useAnimatedProps(() => {
    if (monthCalendarStyle.pointerEvents === 'none') {
      return { 'aria-hidden': true };
    }
    return {};
  }, [monthCalendarStyle]);

  return (
    <View
      testID="EatingLogEntries"
      onLayout={(e) => {
        setParentDimensions(e.nativeEvent.layout);
      }}
      style={{ flexGrow: 1 }}
    >
      <View
        onLayout={(e) => {
          setCalendarHeight(e.nativeEvent.layout.height);
        }}
        style={{
          backgroundColor: theme.color.accentThree300,
          paddingVertical: 20,
          paddingBottom: 30,
        }}
      >
        {parentDimensions.width ? (
          <View style={{ position: 'absolute', top: 20, left: 0, right: 0 }}>
            <EatingLogCalendar
              date={date}
              onChangeDate={setDate}
              width={parentDimensions.width || undefined}
              view="week"
            />
          </View>
        ) : null}
        <Animated.View style={monthCalendarStyle} {...monthCalendarProps}>
          <EatingLogCalendar date={date} onChangeDate={setDate} view="month" />
        </Animated.View>
      </View>
      {parentDimensions.height && calendarHeight ? (
        <BottomSheet
          animatedIndex={animatedIndex}
          enableDynamicSizing={false}
          snapPoints={[
            parentDimensions.height + BOTTOM_SHEET_MARGIN - calendarHeight, // expanded height
            parentDimensions.height + BOTTOM_SHEET_MARGIN - 170, // collapsed height
          ]}
          animateOnMount={false}
          style={{ marginTop: -BOTTOM_SHEET_MARGIN }}
          backgroundStyle={{ borderRadius: 20 }}
          handleIndicatorStyle={{ backgroundColor: theme.color.gray600, width: 36, height: 5 }}
          index={1}
        >
          <BottomSheetScrollView
            contentContainerStyle={{
              paddingHorizontal: 20,
              gap: 25,
              paddingTop: 10,
              flexGrow: 1,
              paddingBottom,
            }}
          >
            <View style={{ gap: 20 }}>
              <View row style={{ justifyContent: 'space-between' }}>
                <Icon
                  name="caret-left"
                  color={theme.color.gray400}
                  aria-label="Previous day"
                  onPress={() => {
                    setDate(formatGQLDate(addDays(parseGQLDateTime(date), -1)));
                  }}
                />
                <Text
                  color={theme.color.gray200}
                  size={21}
                  weight="bold"
                  text={formatDate(parseGQLDateTime(date), {
                    month: 'short',
                    day: 'numeric',
                    weekday: 'short',
                  })}
                />
                <Icon
                  name="caret-right"
                  color={theme.color.gray400}
                  aria-label="Next day"
                  onPress={() => {
                    setDate(formatGQLDate(addDays(parseGQLDateTime(date), 1)));
                  }}
                  disabled={isSameDay(new Date(), parseGQLDateTime(date))}
                />
              </View>
              <EatingLogEntriesList date={date} />
            </View>
          </BottomSheetScrollView>
        </BottomSheet>
      ) : null}
    </View>
  );
};
