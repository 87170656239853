import { ParamListBase, StackNavigationState, TypedNavigator } from '@react-navigation/core';
import { StackNavigationEventMap, StackNavigationOptions } from '@react-navigation/stack';

import {
  BingeEatingMyPlan,
  EditDistractingActivities,
  EditMoodPlan,
  EditPreventingRelapse,
  EditSocialSupport,
} from './screens/index';
import { BingeEatingMyPlanStackParamList } from './types/navigation';

export function registerBingeEatingMyPlanScreens<T extends ParamListBase>(
  stack: TypedNavigator<
    T,
    StackNavigationState<BingeEatingMyPlanStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >,
) {
  const Stack = stack as TypedNavigator<
    BingeEatingMyPlanStackParamList,
    StackNavigationState<BingeEatingMyPlanStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >;
  return (
    <>
      <Stack.Screen name="BingeEatingMyPlan" component={BingeEatingMyPlan} />
      <Stack.Screen name="EditDistractingActivities" component={EditDistractingActivities} />
      <Stack.Screen name="EditMoodPlan" component={EditMoodPlan} />
      <Stack.Screen name="EditPreventingRelapse" component={EditPreventingRelapse} />
      <Stack.Screen name="EditSocialSupport" component={EditSocialSupport} />
    </>
  );
}
