import { useNavigation } from '@react-navigation/native';
import hexToRgba from 'hex-to-rgba';

import { UnorderedList } from '@oui/app-core/src/components/UnorderedList';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';
import { MoodPlan } from '@oui/lib/src/types/avro';

import {
  BingeEatingMyPlanSection,
  MOOD_PLAN_OPTIONS,
  Section,
  useBingeEatingMyPlanContext,
} from '../';

export function MoodPlanCard(props: {
  disabled: boolean;
  highlighted: boolean;
  items: MoodPlan | undefined;
}) {
  const navigation = useNavigation();
  const { primaryColor } = useBingeEatingMyPlanContext();
  const items = props.items ? props.items.moods : undefined;
  const { theme } = useTheme();

  return (
    <BingeEatingMyPlanSection
      title="Mood plan"
      onPress={() => {
        navigation.navigate('EditMoodPlan');
      }}
      testID="MoodPlanCard"
      disabled={props.disabled}
      canEdit={!!items?.length}
      highlighted={props.highlighted}
    >
      {!!items?.length ? (
        <View
          style={{
            marginTop: 15,
            gap: 25,
          }}
        >
          {items.map((item) => (
            <Section
              key={item.mood}
              title={`When I feel ${MOOD_PLAN_OPTIONS[item.mood].label.toLocaleLowerCase()}`}
              headerBackgroundColor={hexToRgba(theme.color.accentTwo300, 0.3)}
            >
              <UnorderedList
                color={primaryColor}
                style={{
                  marginHorizontal: 15,
                }}
                items={item.strategy.map((strategy) => strategy.text)}
                weight="normal"
              />
            </Section>
          ))}
        </View>
      ) : null}
    </BingeEatingMyPlanSection>
  );
}
