import { useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { z } from 'zod';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { FormContainer, RadioFormInput } from '@oui/app-core/src/form';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { useZodForm } from '@oui/lib/src/form';
import {
  getEatingCommitmentHealthyFoodLabel,
  getEatingCommitmentUnhealthyFoodLabel,
} from '@oui/lib/src/getEatingCommitmentEnumLabel';
import { graphql } from '@oui/lib/src/graphql/tada';
import {
  EatingCommitment,
  EatingCommitmentHealthyFoodEnum,
  EatingCommitmentUnhealthyFoodEnum,
} from '@oui/lib/src/types/avro';

export type EditEatingCommitmentsQueryName = 'EditEatingCommitments';
export const EditEatingCommitmentsQuery = graphql(`
  query EditEatingCommitments {
    user {
      ID
      role {
        __typename
        ID
        roleType
        product {
          slug
        }
        eatingCommitmentComposition {
          ID
          sections {
            ID
            json
          }
        }
      }
    }
  }
`);

export const EditEatingCommitmentsCreateMutation = graphql(`
  mutation EditEatingCommitmentsCreateMutation($patientID: UUID!) {
    newCompositionWithTemplate(
      template: EATING_COMMITMENT
      title: "EATING_COMMITMENT"
      patientID: $patientID
    ) {
      ID
      sections {
        ID
        json
      }
    }
  }
`);

export const UpdateCompositionSectionMutation = graphql(`
  mutation UpdateCompositionSection($sectionID: UUID!, $text: Any) {
    updateCompositionSection(sectionID: $sectionID, text: $text) {
      ID
      json
    }
  }
`);

const Schema = z.object({
  sectionID: z.string(),
  increase: z.string(),
  decrease: z.string(),
});

export const EditEatingCommitments = ({ onSave }: { onSave: () => unknown }) => {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const { data, loading, error, refetch } = useQuery(EditEatingCommitmentsQuery);
  const [createComposition, { data: createData }] = useMutation(
    EditEatingCommitmentsCreateMutation,
  );
  const [updateCompositionSection] = useMutation(UpdateCompositionSectionMutation);

  const role = data?.user?.role;

  useEffect(() => {
    if (role && !role?.eatingCommitmentComposition && !loading && !error) {
      void createComposition({ variables: { patientID: role.ID } });
      void refetch();
    }
  }, [role, loading, error, createComposition, refetch]);

  const eatingCommitmentSection =
    role?.eatingCommitmentComposition?.sections[0] ||
    createData?.newCompositionWithTemplate.sections[0];

  const form = useZodForm(Schema, {
    defaultValues: {
      sectionID: eatingCommitmentSection?.ID,
      increase: (eatingCommitmentSection?.json as EatingCommitment)?.increase ?? undefined,
      decrease: (eatingCommitmentSection?.json as EatingCommitment)?.decrease ?? undefined,
    },
  });

  const healthyFoodItems = Object.values(EatingCommitmentHealthyFoodEnum).map((foodItem) => ({
    label: getEatingCommitmentHealthyFoodLabel({ $t, eatingCommitmentHealthyFood: foodItem }),
    value: foodItem,
  }));

  const unhealthyFoodItems = Object.values(EatingCommitmentUnhealthyFoodEnum).map((foodItem) => ({
    label: getEatingCommitmentUnhealthyFoodLabel({ $t, eatingCommitmentUnhealthyFood: foodItem }),
    value: foodItem,
  }));

  return (
    <ScrollView
      testID="EditEatingCommitments_scrollView"
      contentContainerStyle={{ paddingHorizontal: 20, paddingVertical: 25, paddingBottom: 150 }}
    >
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View
          data-testid="EditEatingCommitments"
          style={{
            gap: 25,
          }}
        >
          <View
            style={{
              gap: 5,
            }}
          >
            <Heading level={3} text="Weekly commitment" />
            <Text
              color={theme.color.gray200}
              text="Set two commitments to eating more healthy foods and less unhealthy foods. Every week you’ll be able to track if you’ve been able to stick to it."
            />
          </View>

          <FormContainer {...form}>
            <RadioFormInput
              name="increase"
              label="1. Commit to increasing one healthy food every week."
              innerStyle={{
                marginTop: 10,
              }}
              items={healthyFoodItems}
              aria-label=""
            />

            <RadioFormInput
              name="decrease"
              label="2. Commit to reducing one unhealthy item every week."
              innerStyle={{
                marginTop: 10,
              }}
              items={unhealthyFoodItems}
              aria-label=""
            />

            <Button
              text="Commit"
              testID="EditEatingCommitments_submitButton"
              onPress={form.handleSubmit(async (data) => {
                await updateCompositionSection({
                  variables: {
                    sectionID: data.sectionID,
                    text: {
                      increase: data.increase,
                      decrease: data.decrease,
                    },
                  },
                });
                return onSave();
              })}
              variant="solid"
              alignSelf="flex-start"
            />
          </FormContainer>
        </View>
      )}
    </ScrollView>
  );
};
