import hexToRgba from 'hex-to-rgba';
import produce from 'immer';
import { useCallback } from 'react';

import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';
import { BingeEatingMyPlanCompositionSectionHash } from '@oui/lib/src/bingeEatingmyStoryMyPlanComposition';
import { DetractorStrategy, SupportActivity, SupporterTypeEnum } from '@oui/lib/src/types/avro';
import { Contact } from '@oui/lib/src/types/avro/common';

import { AddContact, Card, EditSocialSupporter, Section } from '../';

export function SocialSupport({
  data,
  onEdit,
}: {
  data: BingeEatingMyPlanCompositionSectionHash['SOCIAL_SUPPORT'];
  onEdit: (data: Partial<BingeEatingMyPlanCompositionSectionHash['SOCIAL_SUPPORT']>) => void;
}) {
  const { theme } = useTheme();

  const updateSupporterActivities = useCallback(
    (contact: Contact, newActivities: SupportActivity[]) => {
      return onEdit(
        produce(data, (draft) => {
          const supporter = draft.json.supporters.find((s) => s.contact.ID === contact.ID);
          if (supporter) {
            supporter.activities = newActivities;
          }
        }),
      );
    },
    [data, onEdit],
  );

  const updateDetractorActivities = useCallback(
    (contact: Contact, newStrategies: DetractorStrategy[]) => {
      return onEdit(
        produce(data, (draft) => {
          const detractor = draft.json.detractors.find((d) => d.contact.ID === contact.ID);
          if (detractor) {
            detractor.strategies = newStrategies;
          }
        }),
      );
    },
    [data, onEdit],
  );

  const onRemove = useCallback(
    (contactID: string) => {
      return onEdit(
        produce(data, (draft) => {
          draft.json.supporters = draft.json.supporters.filter(
            (supporter) => supporter.contact.ID !== contactID,
          );
          draft.json.detractors = draft.json.detractors.filter(
            (detractor) => detractor.contact.ID !== contactID,
          );
        }),
      );
    },
    [data, onEdit],
  );

  const onAddContact = useCallback(
    (contacts: Contact[], type: 'supporter' | 'detractor') => {
      return onEdit(
        produce(data, (draft) => {
          if (type === 'supporter') {
            draft.json.supporters.push(
              ...contacts.map((contact) => ({
                contact,
                type: undefined!,
                activities: [],
              })),
            );
          } else if (type === 'detractor') {
            draft.json.detractors.push(
              ...contacts.map((contact) => ({
                contact,
                strategies: [],
              })),
            );
          }
        }),
      );
    },
    [data, onEdit],
  );

  const onSetRole = useCallback(
    (contact: Contact, role: SupporterTypeEnum) => {
      return onEdit(
        produce(data, (draft) => {
          const supporter = draft.json.supporters.find((s) => s.contact.ID === contact.ID);
          if (supporter) {
            supporter.type = role;
          }
        }),
      );
    },
    [data, onEdit],
  );

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      contentContainerStyle={{ paddingBottom: 20 }}
      testID="SocialSupport_scrollView"
    >
      <Card title="Social support">
        <Text
          color={theme.color.gray300}
          text="Long term, there will be lots of prompts of unhealthy eating in different places and over time. The more people you have supporting your progress, the better."
        />

        <View
          style={{
            marginTop: 40,
            gap: 40,
          }}
        >
          <Section
            title="Supportive"
            headerBackgroundColor={hexToRgba(theme.color.accent300, 0.3)}
            testID="SocialSupport_Section_supportive"
          >
            <View
              style={{
                marginTop: 15,
              }}
            >
              <Text
                style={{
                  marginBottom: 25,
                }}
                color={theme.color.gray300}
                text="Supportive people can be partners, cheerleaders or positive enablers. These are the folks in your life that help you stay on your regular, healthy eating journey."
              />

              {data.json.supporters.map((supporter) => {
                return (
                  <View
                    key={supporter.contact.ID}
                    style={{
                      marginBottom: 45,
                      borderBottomColor: theme.color.gray600,
                      borderBottomWidth: 1,
                      paddingBottom: 45,
                    }}
                  >
                    <EditSocialSupporter
                      type="supporter"
                      contact={supporter}
                      updateDetractorActivities={updateDetractorActivities}
                      updateSupporterActivities={updateSupporterActivities}
                      onRemove={() => onRemove(supporter.contact.ID)}
                      onSetRole={(role) => {
                        onSetRole(supporter.contact, role);
                      }}
                    />
                  </View>
                );
              })}

              <AddContact
                testID="SocialSupport_AddContact_supportive"
                onAdd={(contacts) => onAddContact(contacts, 'supporter')}
              />
            </View>
          </Section>

          <Section
            title="Not supportive"
            headerBackgroundColor={hexToRgba(theme.color.primary300, 0.3)}
            testID="SocialSupport_Section_notSupportive"
          >
            <View
              style={{
                marginTop: 15,
              }}
            >
              <Text
                style={{
                  marginBottom: 25,
                }}
                color={theme.color.gray300}
                text="Non-supportive people or detractors make it harder to keep up with your regular, healthy eating. Think about who might be detractors at home, school, work, family or social gatherings. "
              />

              {data.json.detractors.map((detractor) => {
                return (
                  <View
                    key={detractor.contact.ID}
                    style={{
                      marginBottom: 45,
                      borderBottomColor: theme.color.gray600,
                      borderBottomWidth: 1,
                      paddingBottom: 45,
                    }}
                  >
                    <EditSocialSupporter
                      type="detractor"
                      contact={detractor}
                      updateDetractorActivities={updateDetractorActivities}
                      updateSupporterActivities={updateSupporterActivities}
                      onRemove={() => onRemove(detractor.contact.ID)}
                      onSetRole={() => null}
                    />
                  </View>
                );
              })}

              <AddContact
                testID="SocialSupport_AddContact_notSupportive"
                onAdd={(contacts) => onAddContact(contacts, 'detractor')}
              />
            </View>
          </Section>
        </View>
      </Card>
    </ScrollView>
  );
}
