import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';
import { Fragment } from 'react';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { View } from '@oui/app-core/src/components/View';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';
import {
  EatingScheduleCompositionFragment,
  getEatingLogMealTypeContent,
  getEatingScheduleFromComposition,
  getPrecedingMealTypeForSnack,
  getSnackLabelForTimeOfDay,
} from '@oui/lib/src/eatingLog';
import { formatGQLTime, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { GQLDate, GQLDateTime } from '@oui/lib/src/types/scalars';

import {
  EatingLogEntriesListItem,
  EatingLogEntriesListItemFragment,
} from '../EatingLogEntriesListItem/EatingLogEntriesListItem';

function getTimeOfDayFromGQLDateTime(timestamp: GQLDateTime) {
  return formatGQLTime(parseGQLDateTime(timestamp));
}

function Divider() {
  return <View style={{ height: 1, width: '100%', backgroundColor: '#dee0e5' }} />;
}

export type EatingLogEntriesListQueryName = 'EatingLogEntriesList';
export const EatingLogEntriesListQuery = graphql(
  `
    query EatingLogEntriesList($after: Date!, $before: Date!) {
      user {
        ID
        role {
          ID
          eatingScheduleComposition {
            ...EatingScheduleComposition
          }
          eatingLogEntries(after: $after, before: $before) {
            practiceID
            eatingLogEntry {
              timestamp
              meal
            }
            ...EatingLogEntriesListItem
          }
        }
      }
    }
  `,
  [EatingLogEntriesListItemFragment, EatingScheduleCompositionFragment],
);

/**
 * Responsible for rendering the eating log entries for a given date. It also renders placeholders for entries that have been configured via the eating schedule.
 */
export const EatingLogEntriesList = ({
  date,
  condensed,
}: {
  date: GQLDate;
  condensed?: boolean;
}) => {
  const navigation = useNavigation();
  const { data, loading } = useQuery(EatingLogEntriesListQuery, {
    variables: { after: date, before: date },
  });
  const role = data?.user ? data?.user?.role : null;

  const schedule = getEatingScheduleFromComposition(role?.eatingScheduleComposition);

  /**
   * Produce a list of scheduled meals/snacks from the user's eating schedule
   * for which there are no existing persisted entries
   */
  const scheduledMealsAndSnacks = schedule
    ? [
        role?.eatingLogEntries.some((e) => e.eatingLogEntry.meal === 'BREAKFAST')
          ? null
          : {
              title: getEatingLogMealTypeContent('BREAKFAST').label,
              timeOfDay: schedule.meals.breakfast.time,
              meal: 'BREAKFAST' as const,
            },
        role?.eatingLogEntries.some((e) => e.eatingLogEntry.meal === 'LUNCH')
          ? null
          : {
              title: getEatingLogMealTypeContent('LUNCH').label,
              timeOfDay: schedule.meals.lunch.time,
              meal: 'LUNCH' as const,
            },
        role?.eatingLogEntries.some((e) => e.eatingLogEntry.meal === 'DINNER')
          ? null
          : {
              title: getEatingLogMealTypeContent('DINNER').label,
              timeOfDay: schedule.meals.dinner.time,
              meal: 'DINNER' as const,
            },
        ...schedule.snacks.map((s) => {
          const mealForSnack = getPrecedingMealTypeForSnack(schedule, s.time);
          const hasSnackEntry = role?.eatingLogEntries.some(
            (e) =>
              e.eatingLogEntry.meal === 'SNACK' &&
              getPrecedingMealTypeForSnack(
                schedule,
                getTimeOfDayFromGQLDateTime(e.eatingLogEntry.timestamp),
              ) === mealForSnack,
          );

          if (hasSnackEntry) return null;

          return {
            title: getSnackLabelForTimeOfDay(schedule, s.time),
            timeOfDay: s.time,
            meal: 'SNACK' as const,
          };
        }),
      ].filter((v) => !!v)
    : [];

  const items = [
    ...scheduledMealsAndSnacks.map((placeholder) => ({
      key: placeholder.title + placeholder.timeOfDay,
      placeholder,
    })),
    ...(role?.eatingLogEntries?.map((entry) => ({ key: entry.practiceID, entry })) ?? []),
  ].sort((a, b) => {
    const aTime =
      'entry' in a
        ? getTimeOfDayFromGQLDateTime(a.entry.eatingLogEntry.timestamp)
        : a.placeholder.timeOfDay;
    const bTime =
      'entry' in b
        ? getTimeOfDayFromGQLDateTime(b.entry.eatingLogEntry.timestamp)
        : b.placeholder.timeOfDay;
    return aTime < bTime ? -1 : 1;
  });

  useArtifactRequest('EatingLog', (role?.eatingLogEntries.length ?? 0) > 0);

  return (
    <View style={{ gap: condensed ? 10 : 15 }}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        items.map((entryOrPractice) => {
          return (
            <Fragment key={entryOrPractice.key}>
              <EatingLogEntriesListItem
                {...entryOrPractice}
                onPress={() => {
                  if ('placeholder' in entryOrPractice) {
                    navigation.navigate('NewEatingLogEntry', {
                      meal: entryOrPractice.placeholder.meal,
                      timeOfDay: entryOrPractice.placeholder.timeOfDay,
                    });
                  } else {
                    navigation.navigate('EatingLogEntry', {
                      practiceID: entryOrPractice.entry.practiceID,
                    });
                  }
                }}
                schedule={schedule}
                condensed={condensed}
              />
              {condensed ? <Divider /> : null}
            </Fragment>
          );
        })
      )}
      <EatingLogEntriesListItem
        placeholder={{ title: 'Log meal or snack', timeOfDay: null }}
        onPress={() => {
          navigation.navigate('NewEatingLogEntry', { date });
        }}
        schedule={schedule}
        condensed={condensed}
      />
      {condensed ? <Divider /> : null}
    </View>
  );
};
