import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { useLayoutEffect } from 'react';
import * as z from 'zod';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { View } from '@oui/app-core/src/components/View';
import { FormContainer } from '@oui/app-core/src/form';
import { useCurrentPatientID } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { useZodForm } from '@oui/lib/src/form';
import { formatGQLDate, parseGQLDateAndTime, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { EatingProgressWeightUnitSchema } from '@oui/lib/src/types';
import { GQLDate, GQLDateTime } from '@oui/lib/src/types/scalars';

import BottomShadow from '../../assets/Ellipse.svg';
import {
  RecordFadFixes,
  RecordFadFixesSchema,
} from '../../components/RecordFadFixes/RecordFadFixes';
import {
  RecordProgressBinges,
  RecordProgressBingesSchema,
} from '../../components/RecordProgressBinges/RecordProgressBinges';
import {
  RecordProgressWeight,
  RecordProgressWeightSchema,
} from '../../components/RecordProgressWeight/RecordProgressWeight';
import { StackScreenProps } from '../../types/navigation';

export type RecordProgressQueryName = 'RecordProgress';
export const RecordProgressQuery = graphql(`
  query RecordProgress($practiceID: UUID!) {
    user {
      ID
      role {
        ID
        eatingProgressEntryByID(practiceID: $practiceID) {
          practiceID
          eatingProgressEntry {
            binges
            fadFixes
            timestamp
            weight
            weightUnit
          }
        }
      }
    }
  }
`);

export const UpdateEatingProgressEntryMutation = graphql(`
  mutation UpdateEatingProgressEntry($input: MutationUpdateEatingProgressEntryInput!) {
    updateEatingProgressEntry(input: $input) {
      ... on EatingProgressEntryPractice {
        eatingProgressEntry {
          binges
          fadFixes
          timestamp
          weight
          weightUnit
        }
        practiceID
      }
    }
  }
`);

export const AddEatingProgressEntryMutation = graphql(`
  mutation AddEatingProgressEntry($input: MutationAddEatingProgressEntryInput!) {
    addEatingProgressEntry(input: $input) {
      ... on EatingProgressEntryPractice {
        eatingProgressEntry {
          binges
          fadFixes
          timestamp
          weight
          weightUnit
        }
        practiceID
      }
    }
  }
`);

const Schema = z.object({
  activeStep: z.enum(['fadFixes', 'weight', 'binges']),
  entry: z
    .object({
      binges: z.number().nullish(),
      date: z.string(),
      weight: z.number().nullish(),
      weightUnit: EatingProgressWeightUnitSchema.nullish(),
    })
    .merge(RecordFadFixesSchema)
    .merge(RecordProgressWeightSchema)
    .merge(RecordProgressBingesSchema),
});

type FormData = z.output<typeof Schema>;

export const RecordProgress = () => {
  const { theme } = useTheme();
  const { goBack, setOptions } = useNavigation<StackScreenProps<'RecordProgress'>['navigation']>();
  const {
    params: { practiceID, progressType },
  } = useRoute<StackScreenProps<'RecordProgress'>['route']>();
  const { $t } = useI18n();
  const patientID = useCurrentPatientID();

  const { data } = useQuery(RecordProgressQuery, {
    variables: { practiceID: practiceID as string },
    skip: !practiceID,
  });

  const [updateEatingProgressEntry] = useMutation(UpdateEatingProgressEntryMutation);
  const [addEatingProgressEntry] = useMutation(AddEatingProgressEntryMutation);

  useLayoutEffect(() => {
    setOptions({
      title: 'My progress',
      headerLeft: () => (
        <HeaderButtons left>
          <HeaderItem
            testID="RecordProgress_closeButton"
            iconName="arrow-left"
            color={theme.color.primary100}
            title=""
            onPress={() => {
              goBack();
            }}
            aria-label={$t({
              id: 'RecordProgress_cancelButton',
              defaultMessage: 'Go back',
            })}
          />
        </HeaderButtons>
      ),
      headerTitleAlign: 'center',
      headerStyle: {
        backgroundColor: theme.color.gray800,
        shadowColor: 'transparent',
        elevation: 0,
        borderBottomWidth: 0,
      },
    });
  }, [$t, setOptions, goBack, theme]);

  const entry = data?.user?.role?.eatingProgressEntryByID?.eatingProgressEntry;

  const steps: FormData['activeStep'][] = [];
  if (entry?.fadFixes || progressType === 'fadFixes') steps.push('fadFixes');
  if (entry?.weight || progressType === 'weight') steps.push('weight');
  if (entry?.binges || progressType === 'binges') steps.push('binges');

  const form = useZodForm(Schema, {
    defaultValues: {
      activeStep: steps[0],
      entry: {
        fadFixes: entry?.fadFixes ?? null,
        hadFadFixes: !!entry?.fadFixes,
        weight: entry?.weight ?? undefined,
        weightUnit: (entry?.weightUnit as 'KG' | 'LBS') || 'LBS',
        binges: entry?.binges ?? null,
        date: entry?.timestamp
          ? formatGQLDate(parseGQLDateTime(entry?.timestamp))
          : formatGQLDate(),
      },
    },
  });

  const activeStep = form.watch('activeStep');

  const onSave = form.handleSubmit(async (data) => {
    if (entry) {
      await updateEatingProgressEntry({
        variables: {
          input: {
            eatingProgressEntry: {
              weight: data.entry.weight,
              weightUnit: data.entry.weightUnit,
              fadFixes: data.entry.fadFixes,
              binges: data.entry.binges,
              timestamp: parseGQLDateAndTime(data.entry.date, '00:00').toISOString() as GQLDateTime,
            },
            practiceID: practiceID as string,
            practiceValues: {
              date: data.entry.date as GQLDate,
              patientID: patientID!,
              ratings: [],
            },
          },
        },
        refetchQueries: ['MyProgress'],
      });
      const currentStepIndex = steps.indexOf(data.activeStep);
      if (currentStepIndex < steps.length - 1) {
        form.setValue('activeStep', steps[currentStepIndex + 1]);
      } else {
        goBack();
      }
    } else {
      await addEatingProgressEntry({
        variables: {
          input: {
            eatingProgressEntry: {
              weight: data.entry.weight,
              weightUnit: data.entry.weightUnit,
              fadFixes: data.entry.fadFixes,
              binges: data.entry.binges,
              timestamp: parseGQLDateAndTime(data.entry.date, '00:00').toISOString() as GQLDateTime,
            },
            practiceValues: {
              date: data.entry.date as GQLDate,
              patientID: patientID!,
              ratings: [],
            },
          },
        },
        refetchQueries: ['MyProgress'],
      });
      goBack();
    }
  });

  return !patientID ? (
    <ActivityIndicator />
  ) : (
    <View style={{ flex: 1, backgroundColor: theme.color.gray800 }}>
      <BottomShadow
        aria-label=""
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
        }}
      />
      <LinearGradient
        colors={['#F4F6FE', '#DCE2FB']}
        style={{
          flex: 1,
        }}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
      >
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            paddingHorizontal: 20,
            paddingVertical: 30,
          }}
          testID="RecordProgress_scrollView"
        >
          <FormContainer {...form}>
            {!activeStep ? (
              <ActivityIndicator />
            ) : activeStep === 'fadFixes' ? (
              <RecordFadFixes control={form.control} name="entry" onSave={onSave} />
            ) : activeStep === 'binges' ? (
              <RecordProgressBinges control={form.control} name="entry" onSave={onSave} />
            ) : (
              <RecordProgressWeight control={form.control} name="entry" onSave={onSave} />
            )}
          </FormContainer>
        </ScrollView>
      </LinearGradient>
    </View>
  );
};
