import { z } from 'zod';

import { Button } from '@oui/app-core/src/components/Button';
import {
  Control,
  DateFormInput,
  FieldPath,
  FieldValues,
  NumericStepperFormInput,
  useSubFormContext,
} from '@oui/app-core/src/form';

import { RecordProgressCard } from '../RecordProgressCard/RecordProgressCard';

export const RecordProgressBingesSchema = z.object({
  binges: z.number().min(0).nullish(),
  date: z.string(),
});

export function RecordProgressBinges<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  onSave,
}: {
  name: TName;
  control: Control<TFieldValues>;
  onSave: () => Promise<unknown>;
}) {
  const { getName } = useSubFormContext(RecordProgressBingesSchema, control, name);

  return (
    <RecordProgressCard
      testID="RecordProgressBinges"
      title="Binges per week"
      description="How many times did you binge last week?"
      datePicker={
        <DateFormInput
          control={control}
          name={getName('date')}
          label=""
          aria-label="Date"
          maximumDate={new Date()}
          mode="date"
          testID="input.date"
        />
      }
    >
      <NumericStepperFormInput
        control={control}
        name={getName('binges')}
        aria-label="Number of binges"
        min={0}
        max={100}
      />
      <Button variant="solid" text="Done" onPress={onSave} />
    </RecordProgressCard>
  );
}
