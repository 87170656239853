import { Image, Pressable, StyleSheet } from 'react-native';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Pill } from '@oui/app-core/src/components/Pill';
import { Label, Small } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { getEatingLogMealTypeContent, getSnackLabelForTimeOfDay } from '@oui/lib/src/eatingLog';
import { formatGQLTime, parseGQLDateTime, parseGQLTime } from '@oui/lib/src/gqlDate';
import { FragmentOf, graphql, readFragment } from '@oui/lib/src/graphql/tada';
import { EatingSchedule } from '@oui/lib/src/types/avro';

export const EatingLogEntriesListItemFragment = graphql(`
  fragment EatingLogEntriesListItem on EatingLogEntryPractice {
    practiceID
    imageUrl
    eatingLogEntry {
      binged
      description
      meal
      timestamp
    }
  }
`);

/**
 * Render a single EatingLogEntry item for display in a list.
 */
export const EatingLogEntriesListItem = (props: {
  onPress: () => void;
  entry?: FragmentOf<typeof EatingLogEntriesListItemFragment>;
  placeholder?: { title: string; timeOfDay: string | null };
  schedule: EatingSchedule | null;
  condensed?: boolean;
}) => {
  const { theme } = useTheme();
  const { formatDate } = useI18n();
  const entry = readFragment(EatingLogEntriesListItemFragment, props.entry);

  const timeOfDayDate = entry
    ? parseGQLDateTime(entry.eatingLogEntry.timestamp)
    : props.placeholder?.timeOfDay
      ? parseGQLTime(props.placeholder?.timeOfDay)
      : undefined;
  const timeOfDayString = timeOfDayDate
    ? formatDate(timeOfDayDate, {
        hour: 'numeric',
        minute: '2-digit',
      })
    : undefined;
  const timeOfDayColor = props.placeholder ? theme.color.gray400 : undefined;
  const thumbSize = props.condensed ? 35 : 100;

  return (
    <Pressable
      onPress={props.onPress}
      aria-label="View meal"
      style={({ pressed }) => ({ opacity: pressed ? 0.5 : undefined })}
    >
      <View row style={{ gap: 15 }}>
        <View
          style={[
            {
              height: thumbSize,
              width: thumbSize,
              backgroundColor: theme.color.accentThree300,
              borderRadius: 10,
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            },
            props.condensed && entry
              ? { borderWidth: 2, borderColor: theme.color.accent100 }
              : undefined,
          ]}
        >
          {entry?.imageUrl ? (
            <Image
              source={{ uri: entry.imageUrl, width: thumbSize, height: thumbSize }}
              style={{ resizeMode: 'cover', borderRadius: 10 }}
            />
          ) : entry ? (
            <Small
              text={entry.eatingLogEntry.description}
              color={theme.color.primary200}
              style={{ paddingHorizontal: 5 }}
              textAlign="center"
            />
          ) : (
            <Icon name="plus" color={theme.color.primary100} size={16} />
          )}
          {entry && props.condensed ? (
            <View
              style={[
                StyleSheet.absoluteFillObject,
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: entry.imageUrl ? undefined : 'white',
                },
              ]}
            >
              <Icon
                name="check"
                color={entry.imageUrl ? 'white' : theme.color.accent100}
                size={16}
              />
            </View>
          ) : null}
          {entry?.eatingLogEntry.binged && !props.condensed ? (
            <View style={[StyleSheet.absoluteFillObject, { padding: 5, justifyContent: 'center' }]}>
              <Pill text="Binged" backgroundColor={theme.color.danger} color="white" />
            </View>
          ) : null}
        </View>
        <View style={{ flex: 1 }}>
          <Label
            text={
              entry?.eatingLogEntry.meal === 'SNACK' && timeOfDayDate && props.schedule
                ? getSnackLabelForTimeOfDay(props.schedule, formatGQLTime(timeOfDayDate))
                : entry?.eatingLogEntry.meal
                  ? getEatingLogMealTypeContent(entry.eatingLogEntry.meal).label
                  : props.placeholder?.title
            }
          />
        </View>
        {timeOfDayString ? (
          <View row style={{ gap: 4 }}>
            <Icon name="clock" color={timeOfDayColor} size={12} />
            <Small text={timeOfDayString} color={timeOfDayColor} />
          </View>
        ) : null}
      </View>
    </Pressable>
  );
};
