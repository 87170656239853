import { ReactNode } from 'react';
import { TouchableOpacity } from 'react-native';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Heading } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';

import { useBingeEatingMyPlanContext } from '../';

export function BingeEatingMyPlanSection(props: {
  testID: string;
  title: string;
  onPress: () => void;
  disabled?: boolean;
  highlighted?: boolean;
  canEdit?: boolean;
  children?: ReactNode;
}) {
  const { theme } = useTheme();
  const { primaryColor } = useBingeEatingMyPlanContext();

  return (
    <TouchableOpacity
      testID={props.testID}
      onPress={props.onPress}
      disabled={props.disabled}
      role="button"
      style={{
        padding: 20,
        borderRadius: 20,
        borderWidth: 2,
        borderColor: props.disabled
          ? theme.color.gray700
          : props.highlighted
            ? primaryColor
            : theme.color.gray700,
        backgroundColor: props.disabled ? theme.color.gray700 : '#fff',
      }}
    >
      <View
        row
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Heading level={3} text={props.title} color={theme.color.gray100} />
        <Icon
          size={16}
          name={props.disabled ? 'lock' : props.canEdit ? 'edit' : 'arrow-right'}
          color={
            props.disabled
              ? theme.color.gray500
              : props.canEdit
                ? theme.color.gray400
                : primaryColor
          }
        />
      </View>
      {props.children}
    </TouchableOpacity>
  );
}
