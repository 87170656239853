import type { RouteProp } from '@react-navigation/core';
import type { StackNavigationProp } from '@react-navigation/stack';

import {
  BooleanParam,
  CoreRootStackParamList,
  DeeplinkConfigShape,
} from '@oui/app-core/src/types/navigation';
import { EatingLogMealType } from '@oui/lib/src/types';

export type EatingLogStackParamList = {
  EatingLog: { date?: string; tab?: BooleanParam };
  EatingLogEntry: { practiceID: string };
  EditEatingCommitments: {};
  EditEatingLogEntry: { practiceID: string };
  EditEatingSchedule: {};
  NewEatingLogEntry: { meal?: EatingLogMealType; date?: string; timeOfDay?: string };
  RecordProgress: {
    practiceID?: string;
    progressType?: 'binges' | 'fadFixes' | 'weight';
  };
};

export const EATING_LOG_DEEPLINK_CONFIG = {
  EatingLog: 'eating-log',
  EatingLogEntry: 'eating-log/:practiceID',
  EditEatingCommitments: 'eating-commitments/edit',
  EditEatingLogEntry: 'eating-log/:practiceID/edit',
  EditEatingSchedule: 'eating-schedule/edit',
  NewEatingLogEntry: 'eating-log/new',
  RecordProgress: 'progress/record',
} as const satisfies DeeplinkConfigShape<keyof EatingLogStackParamList>;

type FullStackParamList = EatingLogStackParamList & CoreRootStackParamList;

export type StackScreenProps<T extends keyof FullStackParamList, Other extends object = {}> = {
  navigation: StackNavigationProp<FullStackParamList, T>;
  route: RouteProp<FullStackParamList, T>;
} & Other;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends FullStackParamList {}
  }
}
