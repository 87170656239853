import { StyleSheet } from 'react-native';

import { Button } from '@oui/app-core/src/components/Button';
import { LottieView } from '@oui/app-core/src/components/LottieView';
import { ModalContainer as Modal } from '@oui/app-core/src/components/ModalContainer';
import { Heading, Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';

/**
 * Displays the users current streak
 */
export const StreakModal = ({
  streak,
  onRequestClose,
}: {
  streak: number;
  onRequestClose: () => void;
}) => {
  const { theme } = useTheme();
  let heading = 'Streak started!';
  let description = 'Logging meals daily grows your streak, but skipping a day resets it!';
  if (streak >= 2) {
    heading = 'Keep that streak going!';
    description = `You logged meals ${streak} days in a row. Keep it up!`;
  }
  if (streak >= 8) {
    heading = 'Hot streak!';
    description = `You logged meals ${streak} days in a row. Keep going!`;
  }
  if (streak >= 15) {
    heading = "You're on fire!";
    description = `You logged meals ${streak} days in a row. Keep that streak going!`;
  }

  return (
    <Modal
      visible
      onRequestClose={onRequestClose}
      style={{
        width: '90%',
        maxWidth: 400,
        backgroundColor: theme.color.primary200,
        padding: 20,
        borderRadius: 20,
        marginHorizontal: 10,
        alignItems: 'center',
      }}
    >
      <Heading level={2} color="white" text={heading} />
      <View
        style={{
          marginBottom: -140,
          // This View needs to have width so that the LottieView can layout properly since it uses
          // its intial layout width to calculate it's final size
          // Since the parent has alignItems center, this View would otherwise have width: 0
          alignSelf: 'stretch',
        }}
      >
        <LottieView source={require('./ANIM_flame_v2.json')} autoPlay loop />
        <View
          style={[
            StyleSheet.absoluteFillObject,
            { alignItems: 'center', justifyContent: 'center' },
          ]}
        >
          <View
            style={[
              {
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                width: 65,
                height: 65,
                borderRadius: 40,
                marginTop: -40,
              },
            ]}
          >
            <Text weight="bold" text={streak.toString()} size={40} />
          </View>
        </View>
      </View>
      <Label text={description} color="white" textAlign="center" />
      <Button
        style={{ marginTop: 25 }}
        variant="solid"
        _accentColor="white"
        text="Continue"
        onPress={onRequestClose}
      />
    </Modal>
  );
};
