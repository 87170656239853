import { z } from 'zod';

import { Button } from '@oui/app-core/src/components/Button';
import {
  Control,
  DateFormInput,
  FieldPath,
  FieldValues,
  NumericStepperFormInput,
  RadioFormInput,
  useSubFormContext,
} from '@oui/app-core/src/form';

import { RecordProgressCard } from '../RecordProgressCard/RecordProgressCard';

export const RecordProgressWeightSchema = z.object({
  weight: z.number().min(0).nullish(),
  date: z.string(),
  weightUnit: z.enum(['KG', 'LBS']),
});

export function RecordProgressWeight<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  onSave,
}: {
  name: TName;
  control: Control<TFieldValues>;
  onSave: () => Promise<unknown>;
}) {
  const { getName } = useSubFormContext(RecordProgressWeightSchema, control, name);

  return (
    <RecordProgressCard
      testID="RecordProgressWeight"
      title="Weight"
      description="What is your current weight?"
      datePicker={
        <DateFormInput
          control={control}
          name={getName('date')}
          label=""
          aria-label="Date"
          maximumDate={new Date()}
          mode="date"
          testID="input.date"
        />
      }
    >
      <NumericStepperFormInput
        control={control}
        name={getName('weight')}
        aria-label="Current weight"
        min={0}
        max={999}
        testID="input.weight"
      />
      <RadioFormInput
        control={control}
        name={getName('weightUnit')}
        horizontal
        aria-label="Weight unit"
        items={[
          { label: 'lbs', value: 'LBS', style: { flexBasis: 80 } },
          { label: 'kg', value: 'KG', style: { flexBasis: 80 } },
        ]}
      />
      <Button variant="solid" text="Done" onPress={onSave} />
    </RecordProgressCard>
  );
}
