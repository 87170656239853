import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import endOfWeek from 'date-fns/endOfWeek';
import isSameMonth from 'date-fns/isSameMonth';
import startOfWeek from 'date-fns/startOfWeek';

import { Heading, Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate, parseGQLDateTime } from '@oui/lib/src/gqlDate';

import { EatingLogCalendar } from '../EatingLogCalendar/EatingLogCalendar';
import { EatingLogEntriesList } from '../EatingLogEntriesList/EatingLogEntriesList';

/**
 * Gives the current user an overview of their eating log.
 */
export const EatingLogOverview = ({}: {}) => {
  const { theme } = useTheme();
  const date = formatGQLDate();
  const { data } = useCurrentUser();
  const createdAt = data?.currentUser?.createdAt ?? formatGQLDate();
  const numWeeks = Math.abs(differenceInCalendarWeeks(new Date(), parseGQLDateTime(createdAt)));
  const { $t, formatDate } = useI18n();

  const startWeek = startOfWeek(new Date());
  const endWeek = endOfWeek(new Date());
  const startDate = formatDate(startWeek, { month: 'short', day: 'numeric' });
  const endDate = formatDate(endWeek, {
    month: isSameMonth(startWeek, endWeek) ? undefined : 'short',
    day: 'numeric',
  });

  let aimForNumDays = 3;
  if (numWeeks >= 1) aimForNumDays = 5;
  if (numWeeks >= 2) aimForNumDays = 7;

  return (
    <View
      style={{
        borderRadius: 20,
        backgroundColor: 'white',
        paddingVertical: 15,
        paddingHorizontal: 20,
        gap: 25,
      }}
    >
      <View style={{ gap: 5 }}>
        <Heading level={3} text="Eating log" />
        <Text
          text={$t(
            {
              id: 'EatingLogOverview_goalDescription',
              defaultMessage:
                'Aim to log at least one meal per day for {count, plural, one{1 day} =7{every day} other{# days}} this week ({startDate}-{endDate}).',
            },
            { count: aimForNumDays, startDate, endDate },
          )}
        />
      </View>
      <View style={{ gap: 20 }}>
        <Label color={theme.color.gray300} text="Today’s meals" small />
        <EatingLogEntriesList date={date} condensed />
      </View>
      <EatingLogCalendar date={date} view="condensed" onChangeDate={() => {}} />
    </View>
  );
};
