import { ReactNode } from 'react';
import { Image } from 'react-native';

import { ActivityDiaryProvider } from '@oui/activity-diary';
import { ConversationProvider } from '@oui/app-core/src/components/ConversationContext';
import { useTheme } from '@oui/app-core/src/styles';
import { BingeEatingMyPlanProvider } from '@oui/binge-eating-my-plan';
import { EatingLogProvider } from '@oui/eating-log';
import { HopeKitProvider } from '@oui/hope-kit';
import { RelaxProvider } from '@oui/relax-diary';
import { SleepDiaryProvider } from '@oui/sleep-diary';

import { useDailyCheckInContext } from '../DailyCheckInContext/DailyCheckInContext';

export function WidgetProviders({ children }: { children?: ReactNode }) {
  const { theme } = useTheme();
  const { onShowDailyCheckIn } = useDailyCheckInContext();
  return (
    <BingeEatingMyPlanProvider>
      <RelaxProvider showBreathingCircleIllustration={false} accentColor={theme.color.accent100}>
        <ActivityDiaryProvider mainColor={theme.color.accent100}>
          <SleepDiaryProvider>
            <EatingLogProvider
              onChangeStreak={() => {
                return onShowDailyCheckIn('EATING_LOG');
              }}
            >
              <HopeKitProvider
                name="Values kit"
                mainPracticeTileColor={theme.color.primary200}
                images={{
                  emptyState: (
                    <Image
                      source={require('../../assets/hopekit/Empty state BG Illustration.png')}
                      style={{ width: '100%', aspectRatio: 1 }}
                    />
                  ),
                  emptyStateSearch: (
                    <Image
                      source={require('../../assets/hopekit/Search BG Illustration.png')}
                      style={{ width: '100%', aspectRatio: 1 }}
                    />
                  ),
                }}
              >
                <ConversationProvider
                  botName="Thea"
                  showTabsAfterCompletion
                  botAvatar={
                    <Image
                      source={require('../../assets/Avatar.png')}
                      style={{ width: 40, height: 40 }}
                    />
                  }
                >
                  {children}
                </ConversationProvider>
              </HopeKitProvider>
            </EatingLogProvider>
          </SleepDiaryProvider>
        </ActivityDiaryProvider>
      </RelaxProvider>
    </BingeEatingMyPlanProvider>
  );
}
