import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Icon } from '@oui/app-core/src/components/Icon';
import { LottieView } from '@oui/app-core/src/components/LottieView';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { graphql, readFragment } from '@oui/lib/src/graphql/tada';

import confetti from '../../assets/confetti.json';
import {
  EatingCommitmentEntryRow,
  EatingCommitmentEntryRowFragment,
} from '../EatingCommitmentEntryRow/EatingCommitmentEntryRow';

export type EatingCommitmentsQueryName = 'EatingCommitments';
export const EatingCommitmentsQuery = graphql(
  `
    query EatingCommitments {
      user {
        ID
        role {
          ID
          eatingCommitmentEntries {
            practiceID
            ...EatingCommitmentEntryRow
          }
        }
      }
    }
  `,
  [EatingCommitmentEntryRowFragment],
);

const StatsCard = (props: { title: string; duration: string; icon: 'increase' | 'decrease' }) => {
  const { theme } = useTheme();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: 'white',
        borderColor: theme.color.accentThree100,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 20,
        paddingVertical: 20,
        paddingHorizontal: 16,
        alignItems: 'center',
      }}
    >
      <Icon
        name={props.icon}
        size={16}
        color={theme.color.accent100}
        style={{
          marginBottom: 15,
        }}
      />
      <Text
        style={{
          textAlign: 'center',
        }}
        color={theme.color.gray300}
        text={props.title}
      />

      <Heading
        text={props.duration}
        level={2}
        color={theme.color.dark}
        style={{
          marginTop: 5,
        }}
      />
    </View>
  );
};

export const EatingCommitments = () => {
  const { data, loading } = useQuery(EatingCommitmentsQuery);
  const { theme } = useTheme();
  const { $t } = useI18n();
  const [showHealthyConfetti, setShowHealthyConfetti] = useState(false);
  const [showUnhealthyConfetti, setShowUnhealthyConfetti] = useState(false);
  const prevStatsRef = useRef({ healthySuccesses: 0, unhealthySuccesses: 0 });
  const isInitialDataRef = useRef(true);

  const stats = useMemo(() => {
    const entries = data?.user?.role?.eatingCommitmentEntries || [];

    const processedEntries = entries.map((entry) =>
      readFragment(EatingCommitmentEntryRowFragment, entry),
    );

    const totalWeeks = processedEntries.length;
    const healthySuccesses = processedEntries.filter(
      (entry) => entry.eatingCommitmentEntry.increaseSuccess === true,
    ).length;
    const unhealthySuccesses = processedEntries.filter(
      (entry) => entry.eatingCommitmentEntry.decreaseSuccess === true,
    ).length;

    return {
      healthyWeeks: $t(
        {
          id: 'EatingCommitments_healthSuccesses',
          defaultMessage: '{healthySuccesses} of {totalWeeks} weeks',
        },
        {
          healthySuccesses,
          totalWeeks,
        },
      ),
      unhealthyWeeks: $t(
        {
          id: 'EatingCommitments_unhealthySuccesses',
          defaultMessage: '{unhealthySuccesses} of {totalWeeks} weeks',
        },
        {
          unhealthySuccesses,
          totalWeeks,
        },
      ),
      healthySuccesses,
      unhealthySuccesses,
    };
  }, [data, $t]);

  useEffect(() => {
    if (loading || !data) return;

    if (isInitialDataRef.current) {
      isInitialDataRef.current = false;
      prevStatsRef.current = {
        healthySuccesses: stats.healthySuccesses,
        unhealthySuccesses: stats.unhealthySuccesses,
      };
      return;
    }

    if (stats.healthySuccesses > prevStatsRef.current.healthySuccesses) {
      setShowHealthyConfetti(true);
    }
    if (stats.unhealthySuccesses > prevStatsRef.current.unhealthySuccesses) {
      setShowUnhealthyConfetti(true);
    }
    prevStatsRef.current = {
      healthySuccesses: stats.healthySuccesses,
      unhealthySuccesses: stats.unhealthySuccesses,
    };
  }, [loading, data, stats.healthySuccesses, stats.unhealthySuccesses]);

  return (
    <View
      testID="EatingCommitments"
      style={{
        backgroundColor: theme.color.accentThree300,
      }}
    >
      <View style={{ position: 'relative' }}>
        <View
          row
          style={{
            gap: 15,
            padding: 20,
          }}
        >
          <StatsCard
            icon="increase"
            title="Successfully increased healthy food"
            duration={stats.healthyWeeks}
          />
          <StatsCard
            icon="decrease"
            title="Successfully reduced unhealthy food"
            duration={stats.unhealthyWeeks}
          />
        </View>
        {showHealthyConfetti && (
          <View
            style={{
              position: 'absolute',
              left: 20,
              right: '50%',
              zIndex: 10,
              bottom: 20,
            }}
          >
            <LottieView
              source={confetti}
              autoPlay
              onAnimationFinish={() => setShowHealthyConfetti(false)}
            />
          </View>
        )}
        {showUnhealthyConfetti && (
          <View
            style={{
              position: 'absolute',
              left: '50%',
              right: 20,
              zIndex: 10,
              bottom: 20,
            }}
          >
            <LottieView
              source={confetti}
              autoPlay
              onAnimationFinish={() => setShowUnhealthyConfetti(false)}
            />
          </View>
        )}
      </View>

      <View>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View
            style={{
              paddingHorizontal: 22,
              paddingVertical: 20,
              borderTopRightRadius: 30,
              borderTopLeftRadius: 30,
              backgroundColor: 'white',
            }}
          >
            <View
              row
              style={{
                borderBottomColor: theme.color.gray600,
                borderBottomWidth: 1,
                paddingBottom: 10,
              }}
            >
              <Text
                style={{
                  flex: 1,
                  fontWeight: '600',
                }}
                text="Week"
                size={15}
                color={theme.color.gray300}
                weight="semibold"
              />

              <Text
                style={{
                  flex: 1,
                  fontWeight: '600',
                }}
                text="Check-ins"
                color={theme.color.gray300}
              />
            </View>
            {data?.user?.role?.eatingCommitmentEntries.map((entry) => (
              <View
                key={entry.practiceID}
                style={{
                  borderBottomColor: theme.color.gray600,
                  borderBottomWidth: 1,
                  paddingVertical: 20,
                }}
              >
                <EatingCommitmentEntryRow entry={entry} />
              </View>
            ))}
          </View>
        )}
      </View>
    </View>
  );
};
