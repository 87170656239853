import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';

import {
  EatingLogEntry as EatingLogEntryComponent,
  EditEatingCommitments as EditEatingCommitmentsComponent,
} from './components';
import { EatingLog, EatingLogEntry, EditEatingCommitments } from './screens';

registerChatArtifactPreviewComponent('EatingLog', () => EatingLog);

export * from './screens';
export * from './components';
export * from './hooks';
export * from './types/navigation';
export * from './register';

export {
  EatingLogEntry,
  EatingLogEntryComponent,
  EditEatingCommitments,
  EditEatingCommitmentsComponent,
};
