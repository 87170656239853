import { ListItemTextInput } from '@oui/app-core/src/components/ListItemTextInput';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';
import { BingeEatingMyPlanCompositionSectionHash } from '@oui/lib/src/bingeEatingmyStoryMyPlanComposition';

import { Card, useBingeEatingMyPlanContext } from '../';

const SUGGESTED_DISTRACTING_ACTIVITIES = [
  'Watch a video, movie or show',
  'Take a walk or exercise',
  'Connect with friends or family',
  'Take a warm bath or shower',
  'Do relaxation or breathing exercises',
  'Listen to music',
  'Play with a pet',
  'Do a puzzle or play a game',
  'Sing',
];

export function DistractingActivities({
  data,
  onEdit,
}: {
  data: BingeEatingMyPlanCompositionSectionHash['DISTRACTING_ACTIVITIES'];
  onEdit: (
    data: Partial<BingeEatingMyPlanCompositionSectionHash['DISTRACTING_ACTIVITIES']>,
  ) => void;
}) {
  const { theme } = useTheme();
  const { primaryColor } = useBingeEatingMyPlanContext();

  return (
    <>
      <ScrollView
        style={{ flex: 1, backgroundColor: theme.color.gray800 }}
        contentContainerStyle={{ paddingBottom: 20 }}
        testID="DistractingActivities_scrollView"
      >
        <Card title="Distracting activities">
          <View
            style={{
              gap: 25,
              paddingBottom: 40,
            }}
          >
            <Text
              color={theme.color.gray300}
              text="Urges can arise that may hinder your regular eating plan. List strategies that could work for you when you have an urge to binge."
            />
            <ListItemTextInput
              testID="DistractingActivities_distractingActivitiesInput"
              aria-label={undefined}
              autoFocus
              value={data.json ?? []}
              onChangeValue={(newDistractingActivities) =>
                onEdit({
                  ...data,
                  json: newDistractingActivities,
                })
              }
              bulletColor={primaryColor}
              suggestions={{
                accordionText: 'Select from suggested ways',
                items: SUGGESTED_DISTRACTING_ACTIVITIES,
              }}
              minHeight={150}
            />
          </View>
        </Card>
      </ScrollView>
    </>
  );
}
