import { useEffect } from 'react';
import { Image, StyleSheet } from 'react-native';

import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { View } from '@oui/app-core/src/components/View';
import { useAddAction } from '@oui/app-core/src/hooks/practices';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';
import { useProgressByContent } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { ActionType } from '@oui/lib/src/types';

// @ts-expect-error png module declaration missing
import Dots from '../../assets/dots.png';
import {
  DistractingActivitiesCard,
  MoodPlanCard,
  PreventingRelapseCard,
  SocialSupportCard,
  useBingeEatingMyPlanContext,
} from '../../components';
import { useBingeEatingMyPlanComposition } from '../../hooks';

export function BingeEatingMyPlan() {
  const { $t } = useI18n();
  const myPlanTheme = useBingeEatingMyPlanContext();
  const [addAction] = useAddAction();

  const { data, loading } = useBingeEatingMyPlanComposition({
    createIfUndefined: true,
  });

  const { data: progress } = useProgressByContent();

  // TODO we could improve the completion logic here by understanding which
  // session is currently active and checking the corresponding MyPlan section
  // content for data
  useArtifactRequest('BingeEatingMyPlan', true);

  const hasData =
    !loading &&
    !!(
      data?.sections.DISTRACTING_ACTIVITIES.json.length ||
      data?.sections.MOOD_PLAN.json.moods.length ||
      data?.sections.SOCIAL_SUPPORT.json.detractors.length ||
      data?.sections.SOCIAL_SUPPORT.json.supporters.length ||
      data?.sections.PREVENTING_RELAPSE.json.relapsePlans.length
    );
  useEffect(() => {
    if (hasData) {
      addAction({
        actionType: ActionType.MY_PLAN_REVIEW,
      });
    }
  }, [hasData, addAction]);

  return (
    <RoundedSection
      color={myPlanTheme.backgroundColor}
      secondaryColor="white"
      applyHeaderOptions
      title={$t({
        id: 'BingeEatingMyPlan_title',
        defaultMessage: 'MyPlan',
      })}
      testID="BingeEatingMyPlan_scrollView"
    >
      <View
        // applying absolute positioning to the Image directly doesn't fill the
        // entire scroll container. Instead, it stops after 100vh
        style={[StyleSheet.absoluteFillObject]}
      >
        <Image
          source={require('../../assets/dots.png')}
          resizeMode="repeat"
          aria-label=""
          style={{ height: '100%', width: '100%' }}
        />
      </View>
      <View
        style={{
          gap: 25,
        }}
      >
        <DistractingActivitiesCard
          disabled={!progress?.REMOVING_BARRIERS?.completion}
          highlighted={false}
          items={data?.sections['DISTRACTING_ACTIVITIES'].json}
        />
        <MoodPlanCard
          disabled={!progress?.MOOD_MODULATION?.completion}
          highlighted={false}
          items={data?.sections['MOOD_PLAN'].json}
        />
        <SocialSupportCard
          disabled={!progress?.SOCIAL_SUPPORT?.completion}
          highlighted={false}
          contacts={data?.sections['SOCIAL_SUPPORT'].json}
        />
        <PreventingRelapseCard
          disabled={!progress?.RELAPSE_PREVENTION?.completion}
          highlighted={false}
          items={data?.sections['PREVENTING_RELAPSE'].json}
        />
      </View>
    </RoundedSection>
  );
}
