import { Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';

export function Section(props: {
  title: string;
  description?: string;
  children?: React.ReactNode;
  headerBackgroundColor?: string;
  testID?: string;
}) {
  return (
    <View testID={props.testID ? `Section_${props.testID}` : undefined}>
      <View
        style={{
          backgroundColor: props.headerBackgroundColor
            ? props.headerBackgroundColor
            : 'transparent',
          marginHorizontal: -20,
          paddingHorizontal: 19,
          paddingVertical: 5,
        }}
      >
        <Label text={props.title} />
      </View>
      {props.description ? (
        <View
          style={{
            paddingVertical: 25,
          }}
        >
          <Text text={props.description} />
        </View>
      ) : null}
      <View
        style={{
          paddingTop: 10,
        }}
      >
        {props.children}
      </View>
    </View>
  );
}
