import { ParamListBase, StackNavigationState, TypedNavigator } from '@react-navigation/core';
import { StackNavigationEventMap, StackNavigationOptions } from '@react-navigation/stack';

import {
  EatingLog,
  EatingLogEntry,
  EditEatingCommitments,
  EditEatingLogEntry,
  EditEatingSchedule,
  RecordProgress,
} from './screens/index';
import { EatingLogStackParamList } from './types/navigation';

export function registerEatingLogScreens<T extends ParamListBase>(
  stack: TypedNavigator<
    T,
    StackNavigationState<EatingLogStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >,
) {
  const Stack = stack as TypedNavigator<
    EatingLogStackParamList,
    StackNavigationState<EatingLogStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >;
  return (
    <>
      <Stack.Screen name="EatingLog" component={EatingLog} initialParams={{ tab: 'false' }} />
      <Stack.Screen name="NewEatingLogEntry" component={EditEatingLogEntry} />
      <Stack.Screen name="EditEatingLogEntry" component={EditEatingLogEntry} />
      <Stack.Screen name="EatingLogEntry" component={EatingLogEntry} />
      <Stack.Screen
        name="EditEatingSchedule"
        component={EditEatingSchedule}
        options={{ title: 'Edit eating schedule' }}
      />
      <Stack.Screen
        name="EditEatingCommitments"
        component={EditEatingCommitments}
        options={{ title: 'Edit commitments' }}
      />
      <Stack.Screen
        name="RecordProgress"
        component={RecordProgress}
        options={{ title: 'My progress' }}
      />
    </>
  );
}
