import { useNavigation } from '@react-navigation/native';

import { UnorderedList } from '@oui/app-core/src/components/UnorderedList';
import { DistractingActivities } from '@oui/lib/src/types/avro';

import { BingeEatingMyPlanSection, useBingeEatingMyPlanContext } from '../';

export function DistractingActivitiesCard(props: {
  disabled: boolean;
  highlighted: boolean;
  items: DistractingActivities | undefined;
}) {
  const navigation = useNavigation();
  const { primaryColor } = useBingeEatingMyPlanContext();

  return (
    <BingeEatingMyPlanSection
      title="Distracting activities"
      onPress={() => {
        navigation.navigate('EditDistractingActivities');
      }}
      testID="DistractingActivitiesCard"
      disabled={props.disabled}
      canEdit={!!props.items?.length}
      highlighted={props.highlighted}
    >
      {!!props.items?.length ? (
        <UnorderedList
          color={primaryColor}
          style={{
            marginTop: 15,
            marginHorizontal: 15,
          }}
          items={props.items.map((item) => item.text)}
          weight="normal"
        />
      ) : null}
    </BingeEatingMyPlanSection>
  );
}
