import hexToRgba from 'hex-to-rgba';
import { useCallback, useMemo } from 'react';

import { CheckboxListInput } from '@oui/app-core/src/components/CheckboxListInput';
import { ListItemTextInput } from '@oui/app-core/src/components/ListItemTextInput';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';
import { BingeEatingMyPlanCompositionSectionHash } from '@oui/lib/src/bingeEatingmyStoryMyPlanComposition';
import { recordKeys } from '@oui/lib/src/recordKeys';
import {
  PreventingRelapseReasonEnum,
  PreventingRelapseStrategy,
  PreventingRelapse as PreventingRelapseType,
} from '@oui/lib/src/types/avro';

import { Card, Section, useBingeEatingMyPlanContext } from '../';

const options = {
  LOSS: {
    label: 'Loss of a loved one',
    suggestions: [
      'Seek grief counseling',
      'Reach out for support',
      'Practice self-compassion',
      'Stick to regular meals',
      'Engage in comforting hobbies',
      'Journal your emotions',
      'Avoid emotional eating triggers',
      'Connect with loved ones',
      'Join a grief support group',
    ],
  },
  CHANGES: {
    label: 'Big life changes (e.g. moving)',
    suggestions: [
      'Establish a meal routine',
      'Unpack the kitchen first',
      'Plan meals during the move',
      'Prioritize relaxation activities',
      'Stay hydrated',
      'Seek help with the move',
      'Take short walks during breaks',
      'Schedule check-ins with support',
      'Plan social connections',
    ],
  },
  CONFLICT: {
    label: 'Conflict with family or friends',
    suggestions: [
      'Practice conflict resolution',
      'Step away from heated moments',
      'Vent to a neutral party',
      'Avoid stress-eating zones',
      'Schedule self-care time',
      'Maintain regular eating patterns',
      'Communicate boundaries',
      'Use relaxation techniques',
      'Focus on hobbies or exercise',
    ],
  },
  FINANCIAL: {
    label: 'Financial difficulties',
    suggestions: [
      'Create a budget plan',
      'Seek financial advice',
      'Plan low-cost healthy meals',
      'Avoid impulsive eating',
      'Focus on stress reduction',
      'Find free or low-cost activities',
      'Stick to a routine',
      'Practice mindfulness',
      'Reach out to financial support services',
    ],
  },
  HOLIDAYS: {
    label: 'Holidays / celebrations',
    suggestions: [
      'Eat before parties',
      'Bring healthy dishes',
      'Limit alcohol consumption',
      'Avoid food-focused events',
      'Plan for indulgences',
      'Practice mindful eating',
      'Focus on non-food activities',
      'Set portion limits',
      'Ask for support if overwhelmed',
    ],
  },
  VACATIONS: {
    label: 'Vacations',
    suggestions: [
      'Plan when and where to eat',
      'Stay hydrated',
      'Bring healthy snacks',
      'Read menus ahead of time',
      'Share large dishes',
      'Avoid processed foods',
      'Walk more',
      'Hide the mini-bar key',
      'Aim to have 5 servings of fruits and vegetables a day',
    ],
  },
  STRESS: {
    label: 'Stress from work or school',
    suggestions: [
      'Get enough sleep',
      'Mediate',
      'Deep breathing',
      'Exercise',
      'Continue your regular, healthy eating',
      'Avoid caffeine',
      'Spend time outside',
      'Connect with others',
      'Manage time and priorities',
    ],
  },
  OTHER: {
    label: 'Other',
    suggestions: [
      'Watch a video, movie or show',
      'Take a walk or exercise',
      'Connect with friends or family',
    ],
  },
};

export const PREVENTING_RELAPSE_OPTIONS = options;

const getRelapseReasonItems = recordKeys(options).reduce<Record<string, string>>(
  (carry, reason) => {
    carry[reason] = options[reason].label;
    return carry;
  },
  {},
);

export function PreventingRelapse({
  data,
  onEdit,
}: {
  data: BingeEatingMyPlanCompositionSectionHash['PREVENTING_RELAPSE'];
  onEdit: (data: Partial<BingeEatingMyPlanCompositionSectionHash['PREVENTING_RELAPSE']>) => void;
}) {
  const { theme } = useTheme();
  const { primaryColor } = useBingeEatingMyPlanContext();

  const activeReasons = data.json.relapsePlans;

  const sortedOptions = useMemo(() => {
    return activeReasons.slice().sort((a, b) => {
      const order = Object.keys(options);
      return order.indexOf(a.reason) - order.indexOf(b.reason);
    });
  }, [activeReasons]);

  const relapseCheckboxValues = useMemo(
    () =>
      recordKeys(options)
        .filter((key) => activeReasons.some((m) => m.reason === key))
        .map((key) => key),
    [activeReasons],
  );

  const handleChangeValue = useCallback(
    (values: PreventingRelapseReasonEnum[]) => {
      const updatedReasons = activeReasons.reduce<PreventingRelapseType['relapsePlans']>(
        (acc, currentReason) => {
          if (values.includes(currentReason.reason)) {
            acc.push(currentReason);
          }
          return acc;
        },
        [],
      );

      values.forEach((reason) => {
        if (reason && !activeReasons.some((m) => m.reason === reason)) {
          updatedReasons.push({ reason, strategies: [] });
        }
      });

      return onEdit({
        ...data,
        json: {
          relapsePlans: updatedReasons,
        },
      });
    },
    [activeReasons, data, onEdit],
  );

  const updateReasonStrategies = useCallback(
    (reason: PreventingRelapseReasonEnum, newStrategies: PreventingRelapseStrategy[]) => {
      return onEdit({
        ...data,
        json: {
          relapsePlans: activeReasons.map((m) => {
            if (m.reason === reason) {
              return {
                reason: m.reason,
                strategies: newStrategies,
              };
            }
            return m;
          }),
        },
      });
    },
    [data, activeReasons, onEdit],
  );

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      contentContainerStyle={{ paddingBottom: 20 }}
      testID="PreventingRelapse_scrollView"
    >
      <Card title="Preventing relapse">
        <Text
          color={theme.color.gray300}
          text="Think about high risk situations that could take you off track. It might not feel good to think about failure. But simply by thinking about what might happen can prevent it from happening."
        />

        <View
          style={{
            marginTop: 15,
            gap: 40,
          }}
        >
          <Section title="Pick from the list below of the things that could lead you to relapse.">
            <CheckboxListInput
              items={getRelapseReasonItems}
              onChangeValue={handleChangeValue}
              value={relapseCheckboxValues}
            />
          </Section>

          <View
            style={{
              gap: 25,
            }}
          >
            {sortedOptions.map((item) => {
              return (
                <Section
                  key={item.reason}
                  title={options[item.reason].label}
                  headerBackgroundColor={hexToRgba(theme.color.accentTwo300, 0.3)}
                  testID={`PreventingRelapse_Section_${item.reason}`}
                >
                  <ListItemTextInput
                    testID="PreventingRelapse_preventingRelapseInput"
                    aria-label={undefined}
                    autoFocus
                    value={item.strategies ?? []}
                    onChangeValue={(newReasonStrategies) => {
                      updateReasonStrategies(item.reason, newReasonStrategies);
                    }}
                    bulletColor={primaryColor}
                    suggestions={{
                      accordionText: 'Select from suggested ways',
                      items: options[item.reason].suggestions,
                    }}
                    minHeight={74}
                  />
                </Section>
              );
            })}
          </View>
        </View>
      </Card>
    </ScrollView>
  );
}
