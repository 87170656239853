import { endOfWeek, format, isSameMonth, parse, startOfWeek } from 'date-fns';

import { GQLDate } from './types/scalars';

export function formatDateToWeekRange(
  dateString: string | GQLDate,
  options?: Parameters<typeof startOfWeek>[1],
): string {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());

  const startDate = startOfWeek(date, options);
  const endDate = endOfWeek(date, options);

  const formattedStartDate = format(startDate, 'MMM d');

  if (isSameMonth(startDate, endDate)) {
    const formattedEndDate = format(endDate, 'd');
    return `${formattedStartDate}-${formattedEndDate}`;
  } else {
    return `${formattedStartDate}-${format(endDate, 'MMM d')}`;
  }
}
