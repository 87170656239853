import { z } from 'zod';

import { Button } from '@oui/app-core/src/components/Button';
import {
  Control,
  DateFormInput,
  FieldPath,
  FieldValues,
  RadioFormInput,
  TextFormInput,
  useSubFormContext,
} from '@oui/app-core/src/form';

import { RecordProgressCard } from '../RecordProgressCard/RecordProgressCard';

export const RecordFadFixesSchema = z.object({
  date: z.string(),
  hadFadFixes: z.boolean(),
  fadFixes: z.coerce.number().min(0).nullish(),
});

export function RecordFadFixes<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  onSave,
}: {
  name: TName;
  control: Control<TFieldValues>;
  onSave: () => Promise<unknown>;
}) {
  const { getName, watch } = useSubFormContext(RecordFadFixesSchema, control, name);
  const hadFadFixesWatch = watch('hadFadFixes');

  return (
    <RecordProgressCard
      testID="RecordFadFixes"
      title="Fad fixes per week"
      description="In the last week, have you engaged in vomiting, misusing laxatives, diet pills, overexercising or fasting?"
      datePicker={
        <DateFormInput
          control={control}
          name={getName('date')}
          label=""
          aria-label="Date"
          maximumDate={new Date()}
          mode="date"
          testID="input.date"
        />
      }
    >
      <RadioFormInput
        control={control}
        name={getName('hadFadFixes')}
        aria-label="Did you have any fad fixes?"
        innerStyle={{ flexDirection: 'row' }}
        items={[
          { label: 'Yes', value: true, style: { flexBasis: 90 } },
          { label: 'No', value: false, style: { flexBasis: 90 } },
        ]}
      />
      {hadFadFixesWatch && (
        <TextFormInput
          inputMode="numeric"
          control={control}
          name={getName('fadFixes')}
          label="How many times"
        />
      )}
      {hadFadFixesWatch !== undefined && <Button variant="solid" text="Done" onPress={onSave} />}
    </RecordProgressCard>
  );
}
