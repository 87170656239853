import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useCallback } from 'react';
import { Platform, RefreshControl } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';

import { ActivityDiaryPracticeTile } from '@oui/activity-diary';
import { Icon } from '@oui/app-core/src/components/Icon';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Environment, environment } from '@oui/app-core/src/constants';
import { useActionCounts } from '@oui/app-core/src/hooks/practices';
import { useProgressByContent } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import Sentry from '@oui/app-core/src/sentry';
import { useTheme } from '@oui/app-core/src/styles';
import { BingeEatingMyPlanTile } from '@oui/binge-eating-my-plan';
import { EatingLogPracticeTile } from '@oui/eating-log';
import { HopeKitPracticeTile } from '@oui/hope-kit';
import { ActionType } from '@oui/lib/src/types';
import { RelaxPracticeTile } from '@oui/relax-diary';
import { SleepDiaryPracticeTile } from '@oui/sleep-diary';

import { TabScreenProps } from '@src/types/navigation';

const PracticeList = (props: {
  progress: ReturnType<typeof useProgressByContent>['data'];
  countByActionType: Partial<Record<ActionType | 'TOTAL', number>>;
}) => {
  const { navigate } = useNavigation<TabScreenProps<'Practice'>['navigation']>();

  return (
    <View style={{ gap: 20 }}>
      <View row style={{ gap: 20 }}>
        <EatingLogPracticeTile
          onPress={() => navigate('Log')}
          lockedUntil={undefined}
          practiceCount={props.countByActionType.EATING_LOG_ADD ?? 0}
        />
        <HopeKitPracticeTile
          onPress={() => navigate('HopeKit', {})}
          lockedUntil={props.progress.REGULAR_EATING?.completed ? undefined : 2}
          practiceCount={
            (props.countByActionType.HOPE_KIT_ADD ?? 0) +
            (props.countByActionType.HOPE_KIT_REVIEW ?? 0)
          }
        />
      </View>
      <View row style={{ gap: 20 }}>
        <BingeEatingMyPlanTile
          onPress={() => navigate('BingeEatingMyPlan')}
          lockedUntil={props.progress.REMOVING_BARRIERS?.completed ? undefined : 3}
          practiceCount={props.countByActionType.MY_PLAN_REVIEW}
        />
        <RelaxPracticeTile
          onPress={() => navigate('Relaxation')}
          lockedUntil={props.progress.EATING_THE_RIGHT_FOODS?.completed ? undefined : 4}
          practiceCount={props.countByActionType.RELAX}
        />
      </View>
      <View row style={{ gap: 20 }}>
        <ActivityDiaryPracticeTile
          onPress={() => navigate('ActivityDiary', {})}
          lockedUntil={props.progress.EXERCISE_AND_SLEEP?.completed ? undefined : 5}
          practiceCount={
            (props.countByActionType.ACTIVITY_ADD ?? 0) +
            (props.countByActionType.ACTIVITY_RATE ?? 0)
          }
        />
        <SleepDiaryPracticeTile
          onPress={() => navigate('SleepDiary', {})}
          lockedUntil={props.progress.EXERCISE_AND_SLEEP?.completed ? undefined : 5}
          practiceCount={
            (props.countByActionType.SLEEP_DIARY_ENTRY_MORNING ?? 0) +
            (props.countByActionType.SLEEP_DIARY_ENTRY_NIGHT ?? 0)
          }
        />
      </View>
    </View>
  );
};

export function Practice() {
  const isFocused = useIsFocused();
  const { data: progress, refetch, loading } = useProgressByContent();
  const { data: countByActionType, refetch: refetchActionCounts } = useActionCounts();
  const { $t } = useI18n();
  const { theme } = useTheme();

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        void ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      }
      try {
        void refetch();
        void refetchActionCounts();
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }
      return () => {
        if (Platform.OS !== 'web') {
          void ScreenOrientation.unlockAsync();
        }
      };
    }, [refetch, refetchActionCounts]),
  );

  return (
    <View style={{ flex: 1 }}>
      {isFocused ? <SystemBars style="dark" /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={!!loading}
            onRefresh={() => {
              Promise.all([refetch(), refetchActionCounts()]);
            }}
            title={$t({ id: 'Practice_refreshIndicator', defaultMessage: 'Update' })}
            progressViewOffset={150}
          />
        }
        testID="Practice_scrollView"
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingBottom: 150,
        }}
      >
        <TabHeader heading={$t({ id: 'Practice_heading', defaultMessage: 'Practice' })} />
        <View
          style={{
            marginHorizontal: -20,
            backgroundColor: theme.color.dark,
            padding: 16,
            marginBottom: 20,
            justifyContent: 'center',
            gap: 12,
          }}
          row
        >
          <Icon name="practice" color="white" size={24} />
          <Text
            text={$t(
              {
                id: 'Practice_totalCompletedPractices',
                defaultMessage: '{count} total practices done',
              },
              { count: countByActionType.TOTAL ?? 0 },
            )}
            weight="semibold"
            size={21}
            color="white"
          />
        </View>
        <PracticeList progress={progress} countByActionType={countByActionType} />
      </ScrollView>
    </View>
  );
}
